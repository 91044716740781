/* eslint-disable no-param-reassign */
const mutations = {
  SET_PRODUCTS(state, products) {
    state.products = products;
  },
  SET_HAS_MORE(state, status) {
    state.hasMore = status;
  },
  SET_LOADING_STATUS(state, status) {
    state.loading = status;
  },
  SET_LAST_VISIBLE(state, data) {
    state.lastVisible = data;
  },
  RESET_PRODUCTS(state) {
    state.products = {};
    state.hasMore = true;
    state.loading = false;
    state.lastVisible = '';
  },
};

export default mutations;
