<template lang="pug">
  base-card.card(:key="key")
    div.image-wrapper
      img(
        :src="data.img"
        :srcset="data.imgSet"
        :sizes="data.sizes"
        :alt="`model_${data.id}_img`"
      )
    div.card-title {{ data.title }}
    div.card-desc {{ data.description }}
    div.flex-space-between
      div
        div.price {{ data.price | euro }}
        div.desc {{ data.priceNote }}
      base-button.cart-button(
        v-if="data.canPurchase"
        :label="label"
        @onClick="$emit('addToCart', data.id)"
      )
    div.discount(v-if="data.discount") {{ data.discount }}%
</template>
<script>
export default {
  name: 'Product',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    adding: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      key: 0,
    };
  },
  computed: {
    label() {
      if (this.adding) {
        return 'Adding...';
      }

      return this.data.id === 'customised' ? 'Customise' : 'Add to Cart';
    },
  },
  watch: {
    adding: {
      immediate: true,
      handler(val, oldVal) {
        if (val !== oldVal) {
          this.key += 1;
        }
      },
    },
  },
};
</script>
