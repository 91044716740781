import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export const generateDefaultState = () => ({
  currentStep: 1,
  data: {},
  loading: false,
  error: '',
});

const state = generateDefaultState();

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
