<template lang="pug">
  div#address.tab-content
    section.form
      h1.profile-head Invoice Address
      div.flex.flex-wrap.-mx-16.mb-20(:key="`ia_${iaKey}`")
        div.form__input.w-60
          base-address-auto-complete(
            label="Street, Number"
            placeholder="Street and Number"
            id="invoice-address"
            :value="invoiceAddress.street"
            @change="handleInvoiceAddressChange"
          )
        div.form__input.w-40
          base-input(
            class="input-group"
            label="ZIP Code"
            placeholder="ZIP Code"
            :value.sync="invoiceAddress.zip"
            full-width
          )
        div.form__input.w-50
          base-input(
            class="input-group"
            label="City"
            placeholder="City"
            :value.sync="invoiceAddress.city"
            full-width
          )
        div.form__input.w-50
          base-input(
            class="input-group"
            label="Country"
            placeholder="Country"
            :value.sync="invoiceAddress.country"
            full-width
          )
      h1.profile-head Shipping Address
      div.flex.flex-wrap.-mx-16(:key="`sa_${saKey}`")
        div.form__input.w-60
          base-address-auto-complete(
            label="Street, Number"
            placeholder="Street and Number"
            id="shipping-address"
            :value="shippingAddress.street"
            @change="handleShippingAddressChange"
          )
        div.form__input.w-40
          base-input(
            class="input-group"
            label="ZIP Code"
            placeholder="ZIP Code"
            :value.sync="shippingAddress.zip"
            full-width
          )
        div.form__input.w-50
          base-input(
            class="input-group"
            label="City"
            placeholder="City"
            :value.sync="shippingAddress.city"
            full-width
          )
        div.form__input.w-50
          base-input(
            class="input-group"
            label="Country"
            placeholder="Country"
            :value.sync="shippingAddress.country"
            full-width
          )

      base-button(
        label="Update"
        class="login-button"
        :busy="loading"
        full-width
        @onClick="saveData"
      )
      div.error(
          v-if="error !== ''"
        ) {{ error }}

      div.success(
          v-if="success"
        ) Updated successfully!

</template>

<script>
import firebase from 'firebase';

export default {
  name: 'Address',
  data() {
    return {
      loading: false,
      error: null,
      success: false,
      successTimeout: null,

      iaKey: 1,
      saKey: 1,
      invoiceAddress: {
      },
      shippingAddress: {
      },
    };
  },
  mounted() {
    this.init();
  },
  destroyed() {
    if (this.successTimeout) {
      clearTimeout(this.successTimeout);
    }
  },
  methods: {
    handleInvoiceAddressChange(addr) {
      this.invoiceAddress = addr;
      this.iaKey += 1;
    },
    handleShippingAddressChange(addr) {
      this.shippingAddress = addr;
      this.saKey += 1;
    },
    init() {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          const { uid } = firebase.auth().currentUser;
          this.getCustomerData(uid);
        }
      });
    },
    getCustomerData(uid) {
      const db = firebase.firestore();
      const docRef = db.collection('users').doc(uid);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.invoiceAddress = doc.data().address || {
              street: '',
              city: '',
              country: '',
              zip: '',
            };
            this.shippingAddress = doc.data().shipping_address || {
              street: '',
              city: '',
              country: '',
              zip: '',
            };
            this.iaKey += 1;
            this.saKey += 1;
          } else {
            this.error = 'No such document!';
          }
        })
        .catch((error) => {
          this.error = `Error getting document:${error.message || ''}`;
        });
    },
    saveData() {
      this.loading = true;

      const db = firebase.firestore();
      const { uid } = firebase.auth().currentUser;
      const usersRef = db.collection('users').doc(uid);
      // Set the update of the profile
      return usersRef
        .set(
          {
            address: this.invoiceAddress,
            shipping_address: this.shippingAddress,
          },
          { merge: true },
        )
        .then(() => {
          this.loading = false;

          this.success = true;
          this.successTimeout = setTimeout(() => {
            this.success = false;
          }, 2500);
        })
        .catch((error) => {
          // Handle Errors here.
          const errorMessage = error.message;

          // eslint-disable-next-line no-alert
          this.error = errorMessage;
          this.loading = false;
        });
    },
  },
};
</script>

<style src="./Address.scss" lang="scss" scoped />
