<template lang="pug">
  #auth-container.profile
    base-card.profile-tab(title="Profile")
      div.tab
        div.tab--headers
          div.tab-header-wrapper(
            v-for="tab in TABS"
            :key="tab.key"
            :class="{active: currentTab === tab.key}"
          )
            div.tab-header(
              :class="{active: currentTab === tab.key}"
              @click="toggleTab(tab.key)"
            ) {{ tab.title }}
        div.tab--container
          component(:is="activeComponent")
</template>

<script>
import TAB_COMPONENTS from './components';

const TABS = [
  {
    title: 'General Information',
    key: 'general',
  },
  {
    title: 'Address Information',
    key: 'address',
  },
  {
    title: 'Billing Information',
    key: 'billing',
  },
];

export default {
  name: 'Profile',
  data() {
    return {
      TAB_COMPONENTS,
      TABS,

      activeComponent: TAB_COMPONENTS.general,
      currentTab: 'general',
    };
  },
  methods: {
    toggleTab(updatedTab) {
      this.currentTab = updatedTab;
      this.activeComponent = TAB_COMPONENTS[this.currentTab];
    },
  },
};
</script>

<style src="./Profile.scss" lang="scss" />
