import { getAbsoluteImgUrl } from '@/utils';

const categories = [
  {
    key: 'titanium_screws',
    label: 'Titanium Ti 64 Screws',
    products: [
      {
        id: '52',
        img: getAbsoluteImgUrl('Screw-copy.png'),
        imgSet: `${getAbsoluteImgUrl('Screw-copy-p-500.png')} 500w, ${getAbsoluteImgUrl('Screw-copy.png')} 730w`,
        sizes: '(max-width: 479px) 100vw, (max-width: 767px) 38vw, (max-width: 991px) 23vw, 25vw',
        title: 'Standard 52mm',
        description: '- 3D Printed Titanium Ti 64\n- Porous structure with internal canal\n- Self tapping thread',
        price: 198.64,
        priceNote: 'excl. Tax & Shipping',
        canPurchase: true,
      },
      {
        id: '54',
        img: getAbsoluteImgUrl('Screw-copy.png'),
        imgSet: `${getAbsoluteImgUrl('Screw-copy-p-500.png')} 500w, ${getAbsoluteImgUrl('Screw-copy.png')} 730w`,
        sizes: '(max-width: 479px) 100vw, (max-width: 767px) 38vw, (max-width: 991px) 23vw, 25vw',
        title: 'Standard 54mm',
        description: '- 3D Printed Titanium Ti 64\n- Porous structure with internal canal\n- Self tapping thread',
        price: 198.64,
        priceNote: 'excl. Tax & Shipping',
        canPurchase: true,
      },
      {
        id: '56',
        img: getAbsoluteImgUrl('Screw-copy.png'),
        imgSet: `${getAbsoluteImgUrl('Screw-copy-p-500.png')} 500w, ${getAbsoluteImgUrl('Screw-copy.png')} 730w`,
        sizes: '(max-width: 479px) 100vw, (max-width: 767px) 38vw, (max-width: 991px) 23vw, 25vw',
        title: 'Standard 56mm',
        description: '- 3D Printed Titanium Ti 64\n- Porous structure with internal canal\n- Self tapping thread',
        price: 198.64,
        priceNote: 'excl. Tax & Shipping',
        canPurchase: true,
      },
      {
        id: '58',
        img: getAbsoluteImgUrl('Screw-copy.png'),
        imgSet: `${getAbsoluteImgUrl('Screw-copy-p-500.png')} 500w, ${getAbsoluteImgUrl('Screw-copy.png')} 730w`,
        sizes: '(max-width: 479px) 100vw, (max-width: 767px) 38vw, (max-width: 991px) 23vw, 25vw',
        title: 'Standard 58mm',
        description: '- 3D Printed Titanium Ti 64\n- Porous structure with internal canal\n- Self tapping thread',
        price: 198.64,
        priceNote: 'excl. Tax & Shipping',
        canPurchase: true,
      },
      {
        id: 'customised',
        img: getAbsoluteImgUrl('Screw_Comparison_Front-copy.png'),
        imgSet: `
          ${getAbsoluteImgUrl('Screw_Comparison_Front-copy-p-500.png')} 500w, 
          ${getAbsoluteImgUrl('Screw_Comparison_Front-copy-p-800.png')} 800w, 
          ${getAbsoluteImgUrl('Screw_Comparison_Front-copy.png')} 979w
        `,
        sizes: '(max-width: 479px) 100vw, (max-width: 767px) 38vw, (max-width: 991px) 23vw, 25vw',
        title: 'Customised Screw',
        description: '- Includes Standard Size Features\n- Customise Pores Size & Amount\n- Customise Shaft & Thread Length',
        price: 238.37,
        priceNote: 'excl. Tax & Shipping',
        canPurchase: true,
      },
    ],
  },
  {
    key: 'titanium_equipment',
    label: 'Titanium Ti 64 Equipment',
    products: [
      {
        id: 'head_adapter',
        img: getAbsoluteImgUrl('Head_Adapter_Render_format.png'),
        imgSet: `${getAbsoluteImgUrl('Head_Adapter_Render_format-p-500.png')} 500w, ${getAbsoluteImgUrl('Head_Adapter_Render_format.png')} 644w`,
        sizes: '(max-width: 479px) 100vw, (max-width: 767px) 38vw, (max-width: 991px) 23vw, 25vw',
        title: 'Head Adapter',
        description: '- 3D Printed Titanium Ti 64\n- Easy Tooling & Perfect Fit',
        price: 31.89,
        priceNote: 'excl. Tax & Shipping',
      },
      {
        id: 'tube_adapter',
        img: getAbsoluteImgUrl('Tube_Adapter_format.png'),
        imgSet: `${getAbsoluteImgUrl('Tube_Adapter_format-p-500.png')} 500w, ${getAbsoluteImgUrl('Tube_Adapter_format.png')} 516w`,
        sizes: '(max-width: 479px) 100vw, (max-width: 767px) 38vw, (max-width: 991px) 23vw, 25vw',
        title: 'Tube Adapter',
        description: '- 3D Printed Titanium Ti 64\n- Easy Tooling & Perfect Fit',
        price: 8.04,
        priceNote: 'excl. Tax & Shipping',
      },
      {
        id: 'tube_fixator',
        img: getAbsoluteImgUrl('Tube_Fixator-copy.png'),
        imgSet: `${getAbsoluteImgUrl('Tube_Fixator-copy-p-500.png')} 500w, ${getAbsoluteImgUrl('Tube_Fixator-copy.png')} 624w`,
        sizes: '(max-width: 479px) 100vw, (max-width: 767px) 38vw, (max-width: 991px) 23vw, 25vw',
        title: 'Tube Fixator',
        description: '- 3D Printed Titanium Ti 64\n- Easy Tooling & Perfect Fit',
        price: 37.70,
        priceNote: 'excl. Tax & Shipping',
      },
      {
        id: 'countersink',
        img: getAbsoluteImgUrl('Countersink_Render_format.png'),
        imgSet: `${getAbsoluteImgUrl('Countersink_Render_format-p-500.png')} 500w, ${getAbsoluteImgUrl('Countersink_Render_format.png')} 733w`,
        sizes: '(max-width: 479px) 100vw, (max-width: 767px) 38vw, (max-width: 991px) 23vw, 25vw',
        title: 'Countersink',
        description: '- 3D Printed Titanium Ti 64\n- Easy Tooling & Perfect Fit',
        price: 57.08,
        priceNote: 'excl. Tax & Shipping',
      },
    ],
  },
  {
    key: 'complete_set',
    label: 'Complete Set',
    products: [
      {
        id: 'countersink',
        img: getAbsoluteImgUrl('Complete_set.png'),
        imgSet: `
          ${getAbsoluteImgUrl('Complete_set-p-500.png')} 500w, 
          ${getAbsoluteImgUrl('Complete_set-p-800.png')} 800w, 
          ${getAbsoluteImgUrl('Complete_set-p-1080.png')} 1080w, 
          ${getAbsoluteImgUrl('Complete_set-p-1600.png')} 1600w, 
          ${getAbsoluteImgUrl('Complete_set.png')} 1920w
        `,
        sizes: '(max-width: 479px) 100vw, (max-width: 991px) 38vw, 41vw',
        title: 'Complete Set with Standard Screw',
        description: '- Standard Size Screw with 4% Discount\n- Head Adapter, Tube Adapter, Tube Fixator, Countersink',
        price: 320.54,
        priceNote: 'excl. Tax & Shipping',
        discount: 4,
      },
      {
        id: 'countersink_customised',
        img: getAbsoluteImgUrl('Complete_set.png'),
        imgSet: `
          ${getAbsoluteImgUrl('Complete_set-p-500.png')} 500w, 
          ${getAbsoluteImgUrl('Complete_set-p-800.png')} 800w, 
          ${getAbsoluteImgUrl('Complete_set-p-1080.png')} 1080w, 
          ${getAbsoluteImgUrl('Complete_set-p-1600.png')} 1600w, 
          ${getAbsoluteImgUrl('Complete_set.png')} 1920w
        `,
        sizes: '(max-width: 479px) 100vw, (max-width: 991px) 38vw, 41vw',
        title: 'Complete Set with Customised Screw',
        description: '- Customised Screw with 4% Discount\n- Easy Tooling & Perfect Fit',
        price: 358.74,
        priceNote: 'excl. Tax & Shipping',
        discount: 4,
      },
    ],
  },
];

export { categories };
export default categories;
