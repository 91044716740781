<template lang="pug">
  -
    const NAVBAR_ITEMS = {
      Idea: {
        src: '@/assets/images/idea.png',
        click: 'submitIdea',
      },
      Contact: {
        src: '@/assets/images/letter.png',
        click: 'sendMailTo',
      },
      Logout: {
        src: '@/assets/images/shutdown.png',
        click: 'signOut',
      },
    }

  header
    div.navbar-container
      section.logo r3volutionD

      section.navbar
        a.dropbtn
          v-icon(name="bars" scale="1.5")
        div.dropdown-content: each item, name in NAVBAR_ITEMS
          a.menu-item(@click=`${item.click}`)
            img.icon(src=item.src alt=name)
            = name
</template>

<script>
import firebase from 'firebase';
import { mapGetters } from 'vuex';

export default {
  name: 'Header',
  computed: {
    ...mapGetters({
      user: 'authentication/user',
    }),
  },
  methods: {
    submitIdea() {

    },
    sendMailTo() {

    },
    gotoPage() {
      // eslint-disable-next-line no-alert
      alert('clicked');
    },
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$store.commit('resetState');
        })
        .catch((err) => {
          console.log('> error: signOut', err);
        });
    },
  },
};
</script>

<style src="./Header.scss" lang="scss" scoped />
