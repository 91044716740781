import Vue from 'vue';
import Vuex from 'vuex';
import authentication, { state as authDefaultState } from './authentication';
import products, { state as productsDetaultState } from './products';
import onboarding, { generateDefaultState as onboardingState } from './onboarding';
import shop, { generateDefaultState as shopState } from './shop';

Vue.use(Vuex);

const getDefaultState = () => ({
  authentication: authDefaultState,
  products: { ...productsDetaultState, products: {} },
  onboarding: onboardingState(),
  shop: shopState(),
});

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {
    resetState(state) {
      const defaultState = getDefaultState();
      Object.assign(state, defaultState);
    },
  },
  actions: {},
  modules: {
    authentication,
    products,
    onboarding,
    shop,
  },
});
