<template lang="pug">
  #auth-container
    base-card.onboarding-container
      section.heading
        div.title r3volutionD

      div.flex.relative
        transition(name="fade")
          component(:is="Step")

      div.footer(:class="{last: currentStep === 4}")
        div.error.mt-20(
          v-if="error"
        ) {{ error }}

        div.actions(
          :class="error ? 'mt-10' : 'mt-20'"
        )
          base-button.prev-button(
            v-if="currentStep > 1"
            type="secondary"

            @onClick="prevStep"
          )
            img(
              src="@/assets/images/arrow.svg"
              alt="arrow_icon"
            )
          base-button.next-button(
            :label="currentStep < 4 ? 'Next' : 'Complete'"
            :busy="loading"
            @onClick="nextStep"
          )

        div.dots
          div.dot(
            v-for="id in [1, 2, 3, 4]"
            :key="id"
            :class="{ current: currentStep === id }"
          )
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import steps from './steps';

const { mapState } = createNamespacedHelpers('onboarding');

export default {
  name: 'Onboarding',
  data() {
    return {
      steps,
    };
  },
  computed: {
    ...mapState(['currentStep', 'loading', 'error']),
    Step() {
      return steps[this.currentStep - 1];
    },
  },
  methods: {
    nextStep() {
      if (this.currentStep !== 4) {
        this.$store.dispatch('onboarding/nextStep');
      } else {
        this.$store.dispatch('onboarding/submit');
      }
    },
    prevStep() {
      this.$store.dispatch('onboarding/prevStep');
    },
  },
};
</script>
<style src="./Onboarding.scss" lang="scss" scoped />
