<template lang="pug">
  #auth-container
    base-card.text-center.min-h-0
      div.title Almost done
      div.description.mt-40.leading-20
        div(v-if="getEmail && !resent")
          | We send verification email to
          strong {{' ' + getEmail}}.
        div(v-else-if="resent")
          | Resent Email
          template(v-if="getEmail")
            strong  to {{getEmail}}.
          template(v-else) .
        | Please click on the link to verify your email.

      div.flex-center.mt-40
        base-button(type="link" @onClick="resendEmail") Resend Email
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapState } = createNamespacedHelpers('authentication');

export default {
  name: 'VerifyEmail',
  data() {
    return {
      resent: false,
    };
  },
  computed: {
    ...mapGetters(['getEmail']),
    ...mapState(['error']),
  },
  methods: {
    resendEmail() {
      this.$store.dispatch('authentication/sendVerifyEmail')
        .then(() => {
          this.resent = true;
        });
    },
  },
};
</script>

<style src="./VerifyEmail.scss" lang="scss" scoped />
