<template lang="pug">
  div#general.tab-content
    section.form(:key="key")
      h1.profile-head General Information
      div.form__input
        base-input(
          class="input-group"
          label="Company Name"
          placeholder="Your Company Name"
          :value.sync="formData.name"
          full-width
        )
      div.form__input
        base-input(
          class="input-group"
          label="VAT Number"
          placeholder="Your VAT Number"
          :value.sync="formData.vat"
          full-width
        )
      div.form__input
        base-input(
          class="input-group"
          label="Email Address"
          placeholder="Your Email Address"
          :value.sync="formData.email"
          :readOnly="true"
          full-width
        )
      base-button(
        label="Update"
        class="login-button"
        :busy="loading"
        full-width
        @onClick="saveData"
      )
      div.error(
          v-if="error !== ''"
        ) {{ error }}

      div.success(
          v-if="success"
        ) Updated successfully!

    .profile-block
      h1.dangerous-zone-heading Dangerous Zone
      base-button(
        v-if="!isDeleting"
        type="link"
        class="delete-button"
        @onClick="handleDeleteAccount"
      ) Delete Account
      div.delete-confirm(v-else)
        div.my-10 Are you sure you want to delete your account? All data will be erased.
        div.flex
          base-button(
            class="mr-20"
            @onClick="isDeleting = false"
          ) Cancel
          base-button(
          :busy="deleting"
            @onClick="deleteAccount"
          ) Yes

        div.error(
            v-if="deleteError !== ''"
          ) {{ deleteError }}
</template>

<script>
import firebase from 'firebase';

export default {
  name: 'General',
  data() {
    return {
      loading: false,
      error: null,
      success: false,
      successTimeout: null,
      deleting: false,
      deleteError: null,

      key: 1,
      formData: {
        name: '',
        vat: '',
        email: '',
      },

      isDeleting: false,
    };
  },
  mounted() {
    this.init();
  },
  destroyed() {
    if (this.successTimeout) {
      clearTimeout(this.successTimeout);
    }
  },
  methods: {
    init() {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          const { uid } = firebase.auth().currentUser;
          this.getCustomerData(uid);
        }
      });
    },
    getCustomerData(uid) {
      const db = firebase.firestore();
      const docRef = db.collection('users').doc(uid);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.formData = {
              name: doc.data().name || '',
              vat: doc.data().vat || '',
              email: doc.data().email || '',
            };
            this.key += 1;
          } else {
            this.error = 'No such document!';
          }
        })
        .catch((error) => {
          this.error = `Error getting document:${error.message || ''}`;
        });
    },
    saveData() {
      this.loading = true;

      const db = firebase.firestore();
      const { uid } = firebase.auth().currentUser;
      const usersRef = db.collection('users').doc(uid);
      // Set the update of the profile
      return usersRef
        .set(
          {
            name: this.formData.name,
            vat: this.formData.vat,
            email: this.formData.email,
          },
          { merge: true },
        )
        .then(() => {
          this.loading = false;

          this.success = true;
          this.successTimeout = setTimeout(() => {
            this.success = false;
          }, 2500);
        })
        .catch((error) => {
          // Handle Errors here.
          const errorMessage = error.message;

          // eslint-disable-next-line no-alert
          this.error = errorMessage;
          this.loading = false;
        });
    },
    handleDeleteAccount() {
      this.isDeleting = true;
    },
    deleteAccount() {
      this.deleting = true;

      firebase
        .auth()
        .currentUser.delete()
        .then(() => {
          // User deleted.
          window.location.replace('/');
        })
        .catch((error) => {
          // An error happened.
          this.deleteError = error.message || 'Something went wrong. Please try again.';
          this.deleting = false;
        });
    },
  },
};
</script>

<style src="./General.scss" lang="scss" scoped />
