<template lang="pug">
  div#billing.tab-content
    section.form
      h1.profile-head Active Card
      #paymentInfo.paymentInfo
        .card-logo
          .credit-card-default Default
          #credit_card_logo.credit-card-logo {{ cardLogo }}
            //- v-icon(name="cc-visa" scale="1.5")
        .card-number
          #credit_card_number.credit-card-number {{ cardNumber }}
        .card-expiry
          #credit_card_name.credit-card-name {{ cardName }}
          #credit_card_expiry.credit-card-expiry-date {{ cardExp }}
      #credit_card_form.credit-card-form(
        v-show="showCardElement === true"
      )
        .login-label Card Number
        .w-embed
          form#payment-form(action="/charge" method="post")
            .form-row
              #card-element
              #card-errors
        .profile-row
          .field-wrapper.first-name-wrapper
            .login-label Name on Card
            base-input(
              id="stripe-name"
              class="profile-input-field"
              full-width
            )
        #paymentError.error-message
          | This is some text inside of a div block.
      a#change_card_link.change-credit-card-link(
        @click="toggleCardElement"
      )
        | Change
      base-button.next-button.mt-20(
        v-if="newCard"
        label="Update"
        :busy="cardProceeding"
        @onClick="paymentSaveData"
      )
</template>

<script src="https://js.stripe.com/v3/"></script>
<script>
import firebase from 'firebase';

export default {
  name: 'Billing',
  data() {
    return {
      // Payment
      showCardElement: false,
      stripe: null,
      elements: null,
      card: null,
      paymentCustomerData: {},
      newCard: false,
      cardName: 'Name',
      cardLogo: '',
      cardNumber: '•••• •••• •••• 0000',
      cardExp: '00/00',
      cardProceeding: false,
    };
  },
  mounted() {
    this.initPayment();
  },
  methods: {
    toggleCardElement() {
      this.showCardElement = !this.showCardElement;

      document.getElementById('change_card_link').style.display = 'block';

      if (this.newCard == false) {
        document.getElementById("change_card_link").innerHTML = "Cancel";
        document.getElementById("paymentInfo").style.display = "none";
        document.getElementById("credit_card_form").style.display = "block";
        this.newCard = true;
      } else {
        this.card.clear();
        document.getElementById("change_card_link").innerHTML = "Change";
        document.getElementById("paymentInfo").style.display = "flex";
        document.getElementById("credit_card_form").style.display = "none";
        this.newCard = false;
      }
    },
    initPayment() {
      this.stripe = Stripe('pk_test_15zcp0Vn5kJWr1vxhF78IrOC00tEE3wSdV');
      this.elements = this.stripe.elements();
      this.card = this.elements.create('card');
      this.card.mount('#card-element');
      this.getPaymentCustomerData();
      this.getCustomerStripeSources();
    },
    paymentSaveData() {
      this.submitNewCreditCard();
    },
    getPaymentCustomerData() {
      const { uid } = firebase.auth().currentUser;

      firebase
        .firestore()
        .collection('users')
        .doc(uid)
        .onSnapshot((snapshot) => {
          if (snapshot.data()) {
            this.paymentCustomerData = snapshot.data();
            // startDataListeners();
          } else {
            console.warn(
              `No Stripe customer found in Firestore for user: ${uid}`,
            );
          }
        });
    },
    getCustomerStripeSources() {
      const { uid } = firebase.auth().currentUser;

      /**
       * Get all payment methods for the logged in customer
       */
      firebase
        .firestore()
        .collection('users')
        .doc(uid)
        .collection('payment_methods')
        .orderBy('created', 'desc')
        .limit(1)
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            document.getElementById('change_card_link').style.display = 'none';
            document.getElementById('paymentInfo').style.display = 'none';
            document.getElementById('credit_card_form').style.display = 'block';
            this.newCard = true;
            this.showCardElement = true;
          }
          snapshot.forEach((doc) => {
            const paymentMethod = doc.data();
            if (!paymentMethod.card) {
              return;
            }
            this.cardName = paymentMethod.billing_details.name;
            const paymentData = paymentMethod.card;
            // TODO
            // paymentInfo.style.display = 'flex';
            // credit_card_form.style.display = 'none';
            switch (paymentData.brand) {
              case 'mastercard':
                this.cardLogo = 'MasterCard';
                break;
              case 'american_express':
                this.cardLogo = 'American Express';
                break;
              default:
                this.cardLogo = '';
            }
            this.cardNumber = `•••• •••• •••• ${paymentData.last4}`;
            this.cardExp = `${paymentData.exp_month}/${paymentData.exp_year}`;
          });
        });
    },
    submitNewCreditCard() {
      this.cardProceeding = true;

      const { uid } = firebase.auth().currentUser;

      // activateLoading();
      if (document.getElementById('stripe-name').value === undefined
        || document.getElementById('stripe-name').value === ''
      ) {
        // Display error.message in your UI.
        // deactivateLoading('Continue');
        // Inform the user if there was an error.
        const errorElement = document.getElementById('paymentError');
        errorElement.style.display = 'block';
        errorElement.textContent = 'Please add the name that is on the card.';

        this.cardProceeding = false;
        return;
      }

      this.stripe
        .confirmCardSetup(this.paymentCustomerData.setup_secret, {
          payment_method: {
            card: this.card,
            billing_details: {
              name: document.getElementById('stripe-name').value,
            },
          },
        })
        .then((result) => {
          if (result.error) {
            // Display error.message in your UI.
            // deactivateLoading('Continue');
            // Inform the user if there was an error.
            const errorElement = document.getElementById('paymentError');
            errorElement.style.display = 'block';
            errorElement.textContent = result.error.message;
            this.cardProceeding = false;
          } else {
            firebase
              .firestore()
              .collection('users')
              .doc(uid)
              .collection('payment_methods')
              .add({ id: result.setupIntent.payment_method, default: true })
              .then(() => {
                // The setup has succeeded. Display a success message.
                this.cardProceeding = false;
                alert('Updated card successfully!');
                this.toggleCardElement();
              })
              .catch((error) => {
                // Display error.message in your UI.
                // deactivateLoading('Continue');
                // Inform the user if there was an error.
                const errorElement = document.getElementById('paymentError');
                errorElement.style.display = 'block';
                errorElement.textContent = error.message;

                this.cardProceeding = false;
              });
          }
        });
      const setupIntent = firebase.functions().httpsCallable("stripe-setupIntent");
      setupIntent()
        .then((result) => {
          // Read result of the Cloud Function.
          stripe
            .confirmCardSetup(result.data, {
              payment_method: {
                card: card,
                billing_details: {
                  name: document.getElementById("stripe-name").value,
                },
              },
            })
            .then((result) => {
              if (result.error) {
                // Display error.message in your UI.
                this.cardProceeding = false;
                // Inform the user if there was an error.
                const errorElement = document.getElementById("paymentError");
                errorElement.textContent = result.error.message;
              } else {
                const saveCard = firebase
                  .functions()
                  .httpsCallable("stripe-saveCardtoCustomer");
                saveCard({
                  intent: result.setupIntent,
                  old: payment_id,
                })
                  .then((result) => {
                    // The setup has succeeded. Display a success message.
                    this.cardProceeding = false;
                    this.toggleCardElement();
                  })
                  .catch((error) => {
                    // Display error.message in your UI.
                    this.cardProceeding = false;
                    // Inform the user if there was an error.
                    const errorElement = document.getElementById("paymentError");
                    errorElement.textContent = error.message;
                  });
              }
            });
        })
        .catch((error) => {
          // Getting the Error details.
          this.cardProceeding = false;
          const errorElement = document.getElementById("paymentError");
          errorElement.textContent = error.message;
        });
    },
  }
};
</script>

<style src="./Billing.scss" lang="scss" scoped />
