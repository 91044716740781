<template lang="pug">
  div.step-container
    div.step-title
      | Let's start with your name.
    div.step-sub-title
      | Please fill in the details below so that we can finish setting up your account.

    div.step-content
      div.form__input
        template(v-if="type === 0")
          base-input(
            class="input-group"
            label="Business Name"
            placeholder="Enter your business name"
            type="text"
            :value.sync="name"
            full-width
          )
          base-input(
            class="input-group"
            label="VAT"
            placeholder="Enter your VAT number"
            type="text"
            :value.sync="vat"
            full-width
          )
        template(v-else)
          base-input(
            class="input-group"
            label="Name"
            placeholder="Enter your full name"
            type="text"
            :value.sync="name"
            full-width
          )

</template>
<script>
import StepMixin from './StepMixin';

export default {
  name: 'Step2',
  mixins: [StepMixin],
  computed: {
    type() {
      return this.getData('type');
    },
    name: {
      get() {
        return this.getData('name') || '';
      },
      set(val) {
        this.setData('name', val);
      },
    },
    vat: {
      get() {
        return this.getData('vat') || '';
      },
      set(val) {
        this.setData('vat', val);
      },
    },
  },
};
</script>

<style src="../Onboarding.scss" lang="scss" scoped />
