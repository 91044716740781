<template lang="pug">
  #auth-container
    base-card
      template(v-if="mode === 'resetPassword'")
        section.heading
          div.title r3volutionD
          div.subtitle
            | Reset Password

        section.form
          template(v-if="loading && !mounted")
            base-animation(name="loading-dark")

          template(v-else-if="success")
            div.current-email
              span {{email || 'Unknown'}}

            div.mt-20
              span Password has been reset successfully!
              div.flex-align-center
                span Please sign in
                base-button(type="link" label="here" @onClick="gotoSignIn")

          template(v-else)
            div.current-email
              span {{email || 'Unknown'}}

            div.form__input
              base-input(
                class="input-group"
                label="New Password"
                placeholder="Type in your new password"
                type="password"
                :value.sync="form.password"
                full-width
              )
              base-input(
                class="input-group"
                label="Verify New Password"
                placeholder="Type in your new password"
                type="password"
                :value.sync="form.passwordVerify"
                full-width
              )

            base-button(
              label="Submit"
              class="login-button"
              full-width
              :busy="loading"
              @onClick="submit"
            )
            div.error(
              v-if="error !== ''"
            ) {{ error }}

          base-button(label="Back to login" class="mt-20 p-0" type="link" @onClick="gotoSignIn")

      template(v-else-if="mode === 'verifyEmail'")
        section.heading
          div.title r3volutionD
          template(v-if="emailVerified")
            div.subtitle
              | Email verified

            div
              | Redirecting you to your account...

            div.mt-20.flex-center
              base-button(
                type="link"
                @onClick="gotoDashboard"
              ) Back to the app
          template(v-else-if="loading")
            base-animation(name="loading-dark")
          template(v-else-if="error")
            div.error {{error}}

</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('authentication');

export default {
  name: 'AuthHandler',
  data() {
    return {
      email: '',
      success: false,
      mode: 'reset-password',
      mounted: false,
      emailVerified: false,
      form: {
        passwordVerify: '',
        password: '',
      },

      timeout: null,
    };
  },
  computed: {
    ...mapState(['error', 'loading']),
  },
  mounted() {
    const { oobCode, mode } = this.$route.query;
    this.mode = mode;

    switch (mode) {
      case 'resetPassword':
        this.$store.dispatch('authentication/initResetPassword', oobCode)
          .then((email) => {
            this.email = email;
            this.mounted = true;
          });
        break;
      case 'verifyEmail':
        this.$store.dispatch('authentication/verifyEmail', oobCode)
          .then((res) => {
            this.emailVerified = res;
            if (this.emailVerified) {
              this.timeout = setTimeout(() => this.gotoDashboard(), 1500);
            }
          });
        break;
      default:
        break;
    }
  },
  destroyed() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
  methods: {
    submit() {
      const { password, passwordVerify } = this.form;
      const { oobCode: actionCode } = this.$route.query;

      if (!password) {
        this.$store.commit('authentication/SET_ERROR', 'Password is required.');
        return;
      }

      if (password !== passwordVerify) {
        this.$store.commit('authentication/SET_ERROR', 'Passord mismatch.');
        return;
      }

      this.$store.dispatch('authentication/resetPassword', { actionCode, password })
        .then(() => {
          this.success = true;
        });
    },
    gotoSignIn() {
      this.$router.push('/login');
    },
    gotoDashboard() {
      this.$router.push('/');
    },
  },
};
</script>

<style src='./AuthHandler.scss' lang="scss" scoped />
