<template lang="pug">
  #auth-container
    base-card.card
      section.heading
        div.title r3volutionD
        div.subtitle
          | Welcome back!
          | Enter your email and password to continue.

      section.form
        div.text-left.flex-align-center
          span First time here?
          base-button(type="link" @onClick="gotoSignUp") Create an account

        div.form__input
          base-input(
            class="input-group"
            label="Email address"
            placeholder="Your Email"
            type="email"
            :value.sync="form.email"
            full-width
          )
          base-input(
            class="input-group"
            label="Password"
            placeholder="Your Password"
            type="password"
            :value.sync="form.password"
            full-width
          )

        div.text-right.flex-align-center.flex-justify-end
          span Forgot your password?
          base-button(type="link" @onClick="handleResetPassword") Reset your password
        base-button(label="Login" class="login-button" :busy="loading" @onClick="submit" full-width)
        div.error(
            v-if="error !== ''"
          ) {{ error }}

        TermsAndPolicy
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import TermsAndPolicy from '@/components/auth/TermsAndPolicy';

const { mapState } = createNamespacedHelpers('authentication');

export default {
  name: 'Login',
  components: {
    TermsAndPolicy,
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
    };
  },
  computed: {
    ...mapState(['error', 'loading']),
  },
  methods: {
    submit() {
      this.$store.dispatch('authentication/signIn', this.form)
        .then(() => {
          this.$router.replace({ name: 'Dashboard' });
        })
        .catch(() => {});
    },
    gotoSignUp() {
      this.$router.push('/sign-up');
    },
    handleResetPassword() {
      this.$router.push('/reset-password');
    },
  },
};
</script>
