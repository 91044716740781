<template lang="pug">
  #confirmation
    base-card.product-info
      #ordertable.white-div-dashboard
        table#order-list
          thead
            tr
              th.checkout-item-name
                | Product Name
              th.checkout-item-price
                | Price&nbsp;
                span.text-span-5 excl.VAT
              th.checkout-item-amount
                | Amount
          tbody
            tr(v-if="loading")
              td(colspan="3" class="loading")
                div.loading-wrapper
                  base-animation(name="loading-dark")
            template(v-else)
              tr(
                v-for="product in products"
                :key="product.productId"
              )
                td.checkout-item-name {{ product.productId }}
                td.checkout-item-price {{ product.price | euro }}
                td.checkout-item-amount {{ product.amount }}
    base-card.price-info
      #ordertable.white-div-dashboard
        .checkout-field-wrapper
          .checkout-label Items
          #price.amount-text ...
        .checkout-field-wrapper
          .checkout-label Shipping
          .shipping-carrier-div
            img#carrier_image.carrier-image(
              src='@/assets/images/Deutsche_Post.png'
              width='100'
              alt=''
            )
            #shipping.shipping-amount ...
        .checkout-field-wrapper
          .tax-label Tax Rate
          #tax-rate.tax-amount ...
        .checkout-field-wrapper
          .tax-label Tax Amount
          #tax.tax-amount ...
        .checkout-field-wrapper
          .total-label Total
          #total.total-amount ...
        #paymentError.error-message
          | This is some text inside of a div block.
        base-button.buy-button(
          :busy="buying"
          @onClick="buyNow"
        ) Buy now
        .legal-wrap
          p.legal-text.small
            | By placing your order you agree to r3voluitionD&apos;s&nbsp;
            a(target='_blank' href='https://www.r3volutiond.com/terms-conditions')
              | General Terms &amp; Conditions
            | . Please see our&nbsp;
            a(target='_blank' href='https://www.r3volutiond.com/terms-conditions') Privacy Notice
            |                     and our&nbsp;
            a(target='_blank' href='https://www.r3volutiond.com/terms-conditions') Cookies Notice.
            br
</template>

<script src="https://js.stripe.com/v3/"></script>
<script>
import firebase from 'firebase';

export default {
  name: 'Confirmation',
  data() {
    return {
      stripe: null,
      currentUser: '',
      customerOrder: '',
      paymentMethod: {},
      productInfo: {},

      loading: false,
      buying: false,
      products: [],
      currentPayment: null,

      timeout: null,
    };
  },
  mounted() {
    this.stripe = Stripe('pk_test_15zcp0Vn5kJWr1vxhF78IrOC00tEE3wSdV');
    this.initApp();
  },
  destroyed() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  },
  methods: {
    buyNow() {
      this.buying = true;

      const data = {
        status: "new",
        order_id: this.customerOrder.id,
        payment_id: this.paymentMethod.id,
      };
      firebase
        .firestore()
        .collection("users")
        .doc(this.currentUser.uid)
        .collection("payments")
        .add(data)
        .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
          this.currentPayment = docRef.id;
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
          this.buying = false;
          // deactivateLoading("Buy now");
        });
    },
    initApp() {
      this.loading = true;

      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          this.currentUser = user;
          this.startDataListeners();
          this.getCustomerStripeSources();
          this.getProductInformation();
        }
      });
    },
    startDataListeners() {
      firebase
        .firestore()
        .collection('users')
        .doc(this.currentUser.uid)
        .collection('payments')
        .onSnapshot((snapshot) => {
          snapshot.forEach((doc) => {
            if (doc.id !== this.currentPayment) {
              return;
            }

            const payment = doc.data();

            if (
              (payment.order_id === this.customerOrder.id && payment.status === 'new')
          || payment.status === 'requires_confirmation'
            ) {
              console.log(`Creating Payment for ${payment.amount} €`);
            } else if (
              payment.order_id === this.customerOrder.id
          && payment.status === 'succeeded'
            ) {
              this.timeout = setTimeout(() => {
                this.buying = false;
                this.$router.push({ name: 'Orders' });
              }, 2000);
            } else if (
              payment.order_id === this.customerOrder.id
          && payment.status === 'requires_action'
            ) {
              this.handleCardAction(payment, doc.id);
            } else if (payment.order_id === this.customerOrder.id) {
              console.log(`Payment status: ${payment.status}`);
            }
          });
        });
    },
    handleCardAction(payment, docId) {
      const { error, paymentIntent } = this.stripe.handleCardAction(
        payment.client_secret,
      );
      if (error) {
        alert(error.message);
        payment = error.payment_intent;
      } else if (paymentIntent) {
        payment = paymentIntent;
      }

      firebase
        .firestore()
        .collection('users')
        .doc(this.currentUser.uid)
        .collection('payments')
        .doc(docId)
        .set(payment, { merge: true })
        .then((docRef) => {
          console.log('Document written with ID: ', docRef.id);
        })
        .catch((error) => {
          console.error('Error adding document: ', error);
        });
    },
    getCustomerStripeSources() {
      firebase
        .firestore()
        .collection("users")
        .doc(this.currentUser.uid)
        .collection("payment_methods")
        .where("default", "==", true)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            this.paymentMethod = doc.data() || {};
            if (!this.paymentMethod.card) {
              return;
            }
          });
        })
        .catch(function (error) {
          console.log("Error getting documents: ", error);
        });
    },
    getProductInformation() {
      firebase
        .firestore()
        .collection("products")
        .doc("titanium_screw")
        .collection("prices")
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            this.productInfo = {
              ...this.productInfo,
              [doc.id]: doc.data(),
            };
          });
          this.getCustomerOrder(this.currentUser.uid);
        });
    },
    getCustomerOrder(uid) {
      const db = firebase.firestore();
      let screw_price = 0;
      let set_price = 0;
      this.products = [];
      db.collection("users")
        .doc(uid)
        .collection("cart")
        .orderBy("timestamp", "desc")
        .limit(1)
        .onSnapshot((querySnapshot) => {
          if (!querySnapshot.empty) {
            //We know there is one doc in the querySnapshot
            querySnapshot.forEach((doc) => {
              this.customerOrder = doc;
              const docData = doc.data();

              if (!this.customerOrder.data().shipping_rate || !this.customerOrder.data().price) {
                return;
              }

              //check if it's a standard order or customised order
              if (docData.standard) {
                screw_price = this.productInfo.screw.amount;
                set_price = this.productInfo.set.amount;
              } else {
                screw_price = this.productInfo.custom_screw.amount;
                set_price = this.productInfo.custom_set.amount;
              }

              if (docData.options && docData.options.complete_set) {
                if (parseInt(docData.options.complete_set) < docData.amount) {
                  const single_screw_amount =
                    docData.amount - parseInt(docData.options.complete_set);
                  this.products.push({
                    productId: docData.product_id,
                    amount: single_screw_amount,
                    price: screw_price,
                  });
                }
                this.products.push({
                  productId: docData.product_id + " Set",
                  amount: docData.options.complete_set,
                  price: set_price,
                });
              } else {
                this.products.push({
                  productId: docData.product_id,
                  amount: docData.amount,
                  price: screw_price,
                });
              }

              //show Options
              if (docData.options) {
                for (let [key, value] of Object.entries(docData.options)) {
                  var option_info = {
                    product_id: key,
                    amount: value,
                  };
                  if (value !== 0) {
                    if (key !== "complete_set") {
                      this.products.push({
                        productId: option_info.product_id,
                        amount: option_info.amount,
                        price: this.productInfo[key].amount,
                      });
                    }
                  }
                }
              }

              //Shipping
              var shipping_rate;
              this.customerOrder.data().shipping_rate.rates.forEach((rate) => {
                if (rate.attributes.includes("CHEAPEST")) {
                  shipping_rate = Number(rate.amount);
                  document.getElementById("carrier_image").src =
                    rate.provider_image_200;
                  document.getElementById("shipping").innerHTML =
                    "€ " + rate.amount;
                }
              });

              var items = this.customerOrder.data().price.amount;
              var tax_rate = this.customerOrder.data().price.tax_rate;
              var tax_amount = (items + shipping_rate) * tax_rate;
              var total = items + shipping_rate + tax_amount;

              document.getElementById("price").innerHTML = "€ " + items.toFixed(2);
              document.getElementById("tax-rate").innerHTML = tax_rate * 100 + "%";
              document.getElementById("tax").innerHTML =
                "€ " + tax_amount.toFixed(2);
              document.getElementById("total").innerHTML = "€ " + total.toFixed(2);
            });
          } else {
            alert("Nothing in your shopping cart.");
          }

          this.loading = false;
        });
    },
  },
};
</script>

<style src="./Confirmation.scss" lang="scss" scoped />
