import firebase from 'firebase';

const actions = {
  fetchUser({ commit }, user) {
    commit('SET_LOGGED_IN', user !== null);
    if (user) {
      commit('SET_USER', user);
    } else {
      commit('SET_USER', null);
    }
  },
  async initResetPassword({ commit }, actionCode) {
    commit('SET_LOADING_STATUS', true);
    commit('SET_ERROR', '');

    try {
      const email = await firebase
        .auth()
        .verifyPasswordResetCode(actionCode);
      return email;
    } catch (err) {
      commit('SET_ERROR', err.message || 'An error occurred.');
    } finally {
      commit('SET_LOADING_STATUS', false);
    }

    return null;
  },
  async resetPassword({ commit }, { actionCode, password }) {
    commit('SET_LOADING_STATUS', true);
    commit('SET_ERROR', '');

    try {
      await firebase
        .auth()
        .confirmPasswordReset(actionCode, password);
    } catch (err) {
      commit('SET_ERROR', err.message || 'An error occurred.');
    } finally {
      commit('SET_LOADING_STATUS', false);
    }
  },
  async signUp({ commit }, { email, password }) {
    commit('SET_LOADING_STATUS', true);
    commit('SET_ERROR', '');

    try {
      await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((resp) => {
          const db = firebase.firestore();
          return db.collection('users').doc(resp.user.uid).set({
            email,
          });
        })
        .then(() => {
          const user = firebase.auth().currentUser;
          if (user) {
            commit('SET_USER', user);
          } else {
            throw new Error('Not authenticated.');
          }
        });
    } catch (err) {
      commit('SET_ERROR', err.message || 'An error occurred.');
      throw err;
    } finally {
      commit('SET_LOADING_STATUS', false);
    }
  },
  async signIn({ commit }, { email, password }) {
    commit('SET_LOADING_STATUS', true);
    commit('SET_ERROR', '');

    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((user) => {
          commit('SET_USER', user);
        });
    } catch (err) {
      commit('SET_ERROR', err.message || 'An error occurred.');
      throw err;
    } finally {
      commit('SET_LOADING_STATUS', false);
    }
  },
  async sendResetPasswordEmail({ commit }, email) {
    commit('SET_LOADING_STATUS', true);
    commit('SET_ERROR', '');

    try {
      await firebase
        .auth()
        .sendPasswordResetEmail(email);
    } catch (err) {
      commit('SET_ERROR', err.message || 'An error occurred.');
    } finally {
      commit('SET_LOADING_STATUS', false);
    }
  },
  async verifyEmail({ commit }, actionCode) {
    commit('SET_LOADING_STATUS', true);
    commit('SET_ERROR', '');

    let status = false;

    try {
      await firebase.auth().applyActionCode(actionCode);
      status = true;
    } catch (err) {
      commit('SET_ERROR', err.message || 'An error occurred.');
    } finally {
      commit('SET_LOADING_STATUS', false);
    }

    return status;
  },
  async sendVerifyEmail({ commit }) {
    commit('SET_LOADING_STATUS', true);
    commit('SET_ERROR', '');

    try {
      const user = firebase.auth().currentUser;
      await user.sendEmailVerification();
    } catch (err) {
      commit('SET_ERROR', err.message || 'An error occurred.');
    } finally {
      commit('SET_LOADING_STATUS', false);
    }
  },
};

export default actions;
