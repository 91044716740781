<template lang="pug">
  div.step-container
    div.step-title
      | Complete Submission
    div.step-sub-title
      | Thanks for taking the time to complete your account.
      | Please let us know how you heard about us.

    div.step-content.sources
      div.source(
        v-for="source in sources"
        :key="source.name"
      )
        div.account-type(
          :class="interests && interests[source.name] && 'selected'"
          @click="handleSelectInterest(source.name)"
        )
          div.image-wrapper
            img(
              :src="source.img"
              :alt="`source_${source.name}_img`"
            )
          | {{ source.label }}
      div.source
        div.account-type
          div.image-wrapper
          input.other-source(
            type="text"
            placeholder="Other..."
            :value="other"
            @input="handleOtherChange"
          )

</template>
<script>
import StepMixin from './StepMixin';

const SOURCES = [
  {
    name: 'social-media',
    label: 'Social Media',
    img: 'assets/images/icons8-hashtag_large.png',
  },
  {
    name: 'conference',
    label: 'Conference',
    img: 'assets/images/icons8-presentation.png',
  },
  {
    name: 'publication',
    label: 'Publication',
    img: 'assets/images/icons8-epub.png',
  },
];

export default {
  name: 'Step4',
  mixins: [StepMixin],
  data() {
    return {
      sources: SOURCES,
      other: (this.interests && this.interests.other) || '',
    };
  },
  computed: {
    interests() {
      return this.getData('interests');
    },
    defaultInterestNames() {
      return SOURCES.map(({ name }) => name);
    },
  },
  methods: {
    handleSelectInterest(interest, val = '') {
      if (this.interests) {
        const clonedInterests = JSON.parse(JSON.stringify(this.interests));

        if (interest === 'other') {
          clonedInterests[interest] = val;
        } else if (clonedInterests[interest]) {
          clonedInterests[interest] = '';
        } else {
          clonedInterests[interest] = interest;
        }

        this.setData('interests', clonedInterests);
      } else {
        this.setData('interests', { [interest]: val || interest });
      }
    },
    handleOtherChange(e) {
      this.other = e.target.value;
      this.handleSelectInterest('other', e.target.value);
    },
  },
};
</script>

<style src="../Onboarding.scss" lang="scss" scoped />
