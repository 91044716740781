<template lang="pug">
  base-input(
    class="input-group"
    wrapperClass="address"
    :label="label"
    :placeholder="placeholder"
    type="text"
    :value="address"
    :id="id"
    full-width
  )
</template>
<script>
import places from 'places.js';
import { ALGOLIA_APP_ID, ALGOLIA_API_KEY } from '@/config';

export default {
  name: 'BaseAddressAutoComplete',
  props: {
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Object],
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      autoComplete: null,
    };
  },
  computed: {
    address() {
      if (typeof this.value === 'object') {
        return this.addressTemplate(this.value);
      }

      return this.value;
    },
  },
  mounted() {
    this.autoComplete = places({
      appId: ALGOLIA_APP_ID,
      apiKey: ALGOLIA_API_KEY,
      container: document.querySelector(`#${this.id}`),
    });

    this.autoComplete.on('change', this.handleChange);
    this.autoComplete.on('clear', this.handleReset);
  },
  methods: {
    addressTemplate({
      street, city, zip, country,
    }) {
      if (street || city || zip || country) {
        return `${street || ''}, ${city || ''}, ${zip || ''}, ${country || ''}`;
      }
      return '';
    },
    handleChange({ suggestion }) {
      this.$emit('change', {
        street: suggestion.name || '',
        country: suggestion.country || '',
        country_code: suggestion.countryCode || '',
        city: suggestion.city || suggestion.name,
        zip: suggestion.postcode || '',
        latlng: suggestion.latlng,
      });
    },
    handleReset() {
      this.$emit('change', {
        street: '',
        country: '',
        country_code: '',
        city: '',
        zip: '',
        latlng: '',
      });
    },
  },
};
</script>
