<template lang="pug">
  lottie(:options="{ animationData }")
</template>
<script>
import Lottie from 'vue-lottie';
import animations from '@/assets/lottieFiles';

export default {
  components: {
    Lottie,
  },
  props: {
    name: {
      type: String,
      default: 'empty-box',
    },
  },
  computed: {
    animationData() {
      return animations[this.name];
    },
  },
};
</script>
