/* eslint-disable no-param-reassign */
const mutations = {
  SET_LOADING_STATUS(state, status) {
    state.loading = status;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_DATA(state, { key, value }) {
    const { data } = state;
    const newData = JSON.parse(JSON.stringify(data));
    newData[key] = value;

    state.error = '';
    state.data = newData;
  },
  NEXT_STEP(state) {
    if (state.currentStep === 4) {
      return;
    }

    state.currentStep += 1;
  },
  PREV_STEP(state) {
    if (state.currentStep === 1) {
      return;
    }

    state.currentStep -= 1;
  },
};

export default mutations;
