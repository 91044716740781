const getters = {
  user(state) {
    return state.user;
  },
  getEmail(state) {
    const { user } = state;
    return (user.data && user.data.email) || '';
  },
};

export default getters;
