<template lang="pug">
  #auth-container
    base-card
      section.heading
        div.title r3volutionD
        div.subtitle
          | Start ordering customized products using additive manufacturing.

      section.form
        div.text-left.flex-align-center
          span Already have an account?
          base-button(type="link" @onClick="gotoSignIn") Login here

        div.form__input
          base-input(
            class="input-group"
            label="Email address"
            placeholder="Your Email"
            type="email"
            :value.sync="form.email"
            full-width
          )
          base-input(
            class="input-group"
            label="Password"
            placeholder="Your Password"
            type="password"
            :value.sync="form.password"
            full-width
          )

        base-button(
          label="Sign Up"
          class="login-button"
          :busy="loading"
          full-width
          @onClick="submit"
        )
        div.error(
            v-if="error !== ''"
          ) {{ error }}

        TermsAndPolicy
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import TermsAndPolicy from '@/components/auth/TermsAndPolicy';

const { mapState } = createNamespacedHelpers('authentication');

export default {
  name: 'Login',
  components: {
    TermsAndPolicy,
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
    };
  },
  computed: {
    ...mapState(['loading', 'error']),
  },
  methods: {
    submit() {
      this.$store.dispatch('authentication/signUp', this.form)
        .then(() => this.$router.push('/onboarding'))
        .catch(() => {});
    },
    gotoSignIn() {
      this.$router.push('/login');
    },
  },
};
</script>
