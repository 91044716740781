import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from 'firebase';

import Login from '@/views/auth/Login';
import SignUp from '@/views/auth/SignUp';
import ResetPassword from '@/views/auth/ResetPassword';
import VerifyEmail from '@/views/auth/VerifyEmail';
import Container from '@/views/Container';
import Orders from '@/views/Orders';
import DetailOrder from '@/views/DetailOrder';
import Configurator from '@/views/Configurator';
import Shop from '@/views/Shop';
import Option from '@/views/Option';
import Checkout from '@/views/Checkout';
import Profile from '@/views/Profile';
import Confirmation from '@/views/Confirmation';
import NotFoundComponent from '@/views/NotFoundComponent';
import AuthHandler from '@/views/auth/AuthHandler';
import Onboarding from '@/views/auth/Onboarding';

Vue.use(VueRouter);

const getUserInfo = async (uid) => {
  try {
    const db = firebase.firestore();
    const docRef = db.collection('users').doc(uid);
    const doc = await docRef.get();

    if (doc.exists) {
      return doc.data();
    }
  } catch (err) {
    console.log('Error in getting user info ', err);
  }

  return null;
};

const ifNotAuthenticated = (_, __, next) => {
  firebase.auth().onAuthStateChanged(async (user) => {
    if (!user) {
      next();
    } else {
      const userData = await getUserInfo(user.uid);

      if (!userData) {
        next();
      } else if (userData.onboarded) {
        if (user.emailVerified) {
          next('/dashboard');
        } else {
          next('/verify-email');
        }
      } else {
        next('/onboarding');
      }
    }
  });
};

const ifAuthenticated = (to, __, next) => {
  firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
      if (to.path === '/onboarding' || to.path === '/verify-email') {
        next();
        return;
      }

      const userData = await getUserInfo(user.uid);

      if (userData && userData.onboarded) {
        if (user.emailVerified) {
          next();
        } else {
          next('/verify-email');
        }
      } else if (userData && !userData.onboarded) {
        next('/onboarding');
      } else {
        next('/login');
      }
    } else {
      next('/login');
    }
  });
};

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: SignUp,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/',
    redirect: { name: 'Dashboard' },
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: Onboarding,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Container,
    redirect: { name: 'Orders' },
    beforeEnter: ifAuthenticated,
    children: [
      {
        path: 'orders',
        name: 'Orders',
        component: Orders,
      },
      {
        path: 'orders/:orderId',
        name: 'DetailOrder',
        component: DetailOrder,
      },
      {
        path: 'shop',
        name: 'Shop',
        component: Shop,
      },
      {
        path: 'configurator',
        name: 'Configurator',
        component: Configurator,
      },
      {
        path: 'option',
        name: 'Option',
        component: Option,
      },
      {
        path: 'checkout',
        name: 'Checkout',
        component: Checkout,
      },
      {
        path: 'profile',
        name: 'Profile',
        component: Profile,
      },
      {
        path: 'confirmation',
        name: 'Confirmation',
        component: Confirmation,
      },
    ],
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: VerifyEmail,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/auth-handler',
    name: 'AuthHandler',
    component: AuthHandler,
  },
  {
    path: '*',
    component: NotFoundComponent,
    beforeEnter: ifAuthenticated,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
