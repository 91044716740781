import firebase from 'firebase';

const actions = {
  getStandardModelData({ commit }) {
    commit('SET_LOADING_STATUS', true);

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const db = firebase.firestore();
        db.collection('products')
          .doc('titanium_screw')
          .collection('standard_sizes')
          .get()
          .then((snapshot) => {
            if (!snapshot.empty) {
              const standardModels = {};
              snapshot.forEach((doc) => {
                const model = doc.data();
                standardModels[doc.id] = model;
              });

              commit('SET_STANDARD_MODELS', standardModels);
              commit('SET_LOADING_STATUS', false);
            }
          });
      }
    });
  },
  async addItemToCart({ commit, state }, item) {
    commit('SET_LOADING_STATUS', true);

    const fileName = item;
    const db = firebase.firestore();
    const user = firebase.auth().currentUser;

    try {
      await db.collection('users')
        .doc(user.uid)
        .collection('cart')
        .add({
          category: 'titanium_screw',
          product_id: `Standard_${fileName}mm`,
          downloadurl: state.standardModels[fileName].downloadurl,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          standard: true,
        });

      commit('SET_LOADING_STATUS', false);
      window.location.replace('/dashboard/option');
    } catch (err) {
      commit('SET_LOADING_STATUS', false);
      // eslint-disable-next-line no-alert
      window.alert('Something went wrong. Please try again or contact support.');
    }
  },
};

export default actions;
