<template lang="pug">
  #auth-container
    base-card
      section.heading
        div.title r3volutionD
        div.subtitle
          | Forgot your password?
          | Please enter your email

      section.form
        template(v-if="!messageSent")
          div.form__input
            base-input(
              label="Email address"
              placeholder="Your Email"
              type="email"
              :value.sync="email"
              full-width
            )

          base-button(
            label="Send"
            :busy="loading"
            class="login-button"
            full-width
            @onClick="submit"
          )
          div.error(
            v-if="error !== ''"
          ) {{ error }}
        template(v-else)
          div
            | Reset email has been sent. Please check your inbox.
          base-button(label="OK" class="login-button" @onClick="gotoSignIn" full-width)

        TermsAndPolicy
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import TermsAndPolicy from '@/components/auth/TermsAndPolicy';

const { mapState } = createNamespacedHelpers('authentication');

export default {
  name: 'ResetPassword',
  components: {
    TermsAndPolicy,
  },
  data() {
    return {
      email: '',
      error: '',
      messageSent: false,
    };
  },
  computed: {
    ...mapState(['loading', 'error']),
  },
  methods: {
    submit() {
      this.error = '';
      this.messageSent = false;

      if (this.email) {
        this.$store.dispatch('authentication/sendResetPasswordEmail', this.email)
          .then(() => {
            this.messageSent = true;
          });
      } else {
        this.$store.commit('authentication/SET_ERROR', 'Email is required.');
      }
    },
    gotoSignIn() {
      this.$router.push('/login');
    },
  },
};
</script>

<style src="./ResetPassword.scss" lang="scss" scoped />
