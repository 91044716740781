<template lang="pug">
  #orders
    template(v-if="fields.length")
      base-card(
        title="Orders"
        class="card"
        full-width
      )
        base-table(
          :headers="headers"
          :fields="fields"
          detail-icon
          :loading="loading"
          :hasMore="hasMore"
          @onLoadMore="fetchProducts"
          @onClick="selectRow"
        )
    template(v-else-if="loading")
      div.loading-wrapper
        base-animation(name="loading-dark")
    template(v-else-if="!loading")
      div.flex-center.flex-col
        div.title No Orders
        div.animation-wrapper.mt-20
          base-animation(name="empty-box")
</template>

<script>
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';
import { isTodayofBefore } from '@/utils';

const { mapState } = createNamespacedHelpers('products');

export default {
  name: 'Orders',
  data() {
    return {
      headers: [
        'Product Name',
        'Amount',
        'Status',
        'Date',
        '',
      ],
    };
  },
  computed: {
    ...mapGetters({
      products: 'products/products',
      user: 'authentication/user',
    }),
    ...mapState(['loading', 'hasMore']),
    productOrderIds() {
      return Object.keys(this.products || {});
    },
    fields() {
      return (this.productOrderIds || []).map((productOrderId) => {
        const product = this.products[productOrderId];
        const productName = product.product_id;
        const { amount, status } = product;

        let productStatus = '';
        let statusKey = '';
        switch (true) {
          case (
            !status
          ):
            productStatus = '';
            break;
          case (
            Object.prototype.hasOwnProperty.call(status, 'shipped')
            && isTodayofBefore(status.shipped.toDate())
          ):
            statusKey = 'shipped';
            productStatus = 'Shipped';
            break;
          case (
            Object.prototype.hasOwnProperty.call(status, 'printed')
            && isTodayofBefore(status.printed.toDate())
          ):
            statusKey = 'printed';
            productStatus = 'Printing';
            break;
          case (
            Object.prototype.hasOwnProperty.call(status, 'paid')
            && isTodayofBefore(status.paid.toDate())
          ):
            statusKey = 'paid';
            productStatus = 'Awaiting Print';
            break;
          default:
            productStatus = '';
            break;
        }

        const date = new Date(null);
        if (productStatus) {
          date.setTime(product.status[statusKey].seconds * 1000);
        }
        const dateStr = productStatus ? date.toDateString() : '';

        return {
          'Product Name': productName,
          Amount: amount,
          Status: productStatus,
          Date: dateStr,
        };
      });
    },
  },
  mounted() {
    this.$store.commit('products/RESET_PRODUCTS');
    this.fetchProducts();
  },
  methods: {
    ...mapActions({
      fetchProducts: 'products/fetchProducts',
    }),
    selectRow(index) {
      const orderId = this.productOrderIds[index];
      this.$router.push({ name: 'DetailOrder', params: { orderId } });
    },
  },
};
</script>

<style src="./Orders.scss" lang="scss" scoped />
