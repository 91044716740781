import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export const generateDefaultState = () => ({
  cart: [],
  standardModels: {},
  loading: false,
});

const state = generateDefaultState();

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
