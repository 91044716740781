<template lang="pug">
  -
    const TOPBAR_ITEMS = {
      Orders: {
        src: '@/assets/images/orders.png',
        click: 'gotoPage("Orders")',
      },
      Configurator: {
        src: '@/assets/images/configurator.png',
        click: 'gotoPage("Configurator")',
      },
      Shop: {
        src: '@/assets/images/shop.png',
        click: 'gotoPage("Shop")',
      },
      Profile: {
        src: '@/assets/images/profile.png',
        click: 'gotoPage("Profile")',
      },
    }

  #top-bar: each item, name in TOPBAR_ITEMS
      div.navbar-item(@click=`${item.click}`)
        img.icon(src=item.src alt=name)
        span= name
</template>

<script>
export default {
  name: 'TopBar',
  methods: {
    gotoPage(name) {
      this.$router.push({ name });
    },
  },
};
</script>

<style src="./TopBar.scss" lang="scss" scoped />
