/* eslint-disable no-param-reassign */
const mutations = {
  ADD_TO_CART(state, product) {
    state.cart.push(product);
  },
  SET_LOADING_STATUS(state, status) {
    state.loading = status;
  },
  SET_STANDARD_MODELS(state, models) {
    state.standardModels = models;
  },
};

export default mutations;
