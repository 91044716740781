import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('onboarding');

export default {
  computed: {
    ...mapGetters(['getData']),
  },
  methods: {
    setData(key, value) {
      this.$store.commit('onboarding/SET_DATA', { key, value });
    },
  },
};
