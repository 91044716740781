<template lang="pug">
  -
    const SOCIAL_LINK_ICONS = {
      facebook: "https://uploads-ssl.webflow.com/5cd9dbe554fe38a1c05e6a9b/5cda1f38c90d43d1dc53de2e_Facebook.svg",
      twitter: "https://uploads-ssl.webflow.com/5cd9dbe554fe38a1c05e6a9b/5cda1f38c6b14fc86a26b25f_Twitter.svg",
      youtube: "https://uploads-ssl.webflow.com/5cd9dbe554fe38a1c05e6a9b/5cda1f47ec31a1854e1cf128_Youtube.svg",
      linkedin: "@/assets/images/linkedin.png"
    }

  footer
    section.logo
      img(src="@/assets/images/logo-white.svg")
      div.copyright
        | Copyright 2020 r3volutonD AG. All rights reserved

    section.social-links: each src, name in SOCIAL_LINK_ICONS
      a(href="#")
        img.social-link(src=src alt=name)
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style src="./Footer.scss" lang="scss" scoped />
