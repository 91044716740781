<template lang="pug">
  mixin timeline-elem(icon, label, date)
    div.timeline-icon: v-icon(:name=icon scale="1.5")
    div.timeline-label: span= label
    div.timeline-date: span= date

  #detail-order
    //- div.loading-wrapper(v-if="loading")
    //-   base-animation(name="loading-dark")
    //- template(v-else)
    base-card.timeline-card
      div.timeline
        div.timeline-item(
          v-for="(timelineValue, timelineKey) in productTimeLine"
          :key="timelineKey"
        )
          +timeline-elem(
            'timelineValue.icon',
            '{{ timelineValue.label }}',
            '{{ timelineValue.date }}'
          )
      div.progress-bar-wrap
        div#progress-bar.progress-bar
    .detail
      .screw-container
        div#model.model-viwer
          div#loading_container.loading-container
            div.loading-lottie(
              data-w-id="25ff4fe3-471f-438b-e1b0-e1bdd1510c13"
              data-animation-type="lottie"
              data-src="./loading_dark.json"
              data-loop="1"
              data-direction="1"
              data-autoplay="1"
              data-is-ix2-target="0"
              data-renderer="svg"
              data-default-duration="1.0677343575780234"
              data-duration="0"
            )
            div#loading_porgress.loading-text
              | 0%
      div.w-40
        base-card.product.ml-auto
          div.title Your Product
          div.flex-space-between
            div.info
              span.header Info
              div.desc {{ screw.name || 'Unknown' }}
            div.amount.text-center
              span.header Amount
              div.desc.text-center {{ screw.amount || 0 }}x
        base-card.options.ml-auto
          div.title Your Options
          div.option(v-for="(elem, elemIndex) in productInfo" :key="elemIndex")
            img.option-image(:src="getImgURL(elem.src)")
            span {{ elem.name }}
            span.ml-auto.amount.text-center {{ elem.amount || 0 }}x
</template>

<script>
import firebase from 'firebase';

import { mapGetters } from 'vuex';
import * as THREE from '@/vendors/lib/three.min';
import { getImgURL, isTodayofBefore } from '@/utils';

export default {
  name: 'DetailOrder',
  props: {
    orderId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      screw: {
        amount: 1,
        name: '',
      },
      productTimeLine: {
        confirmation: {
          date: 'Waiting',
          icon: 'thumbs-up',
          label: 'Ordered',
        },
        payment: {
          date: 'Waiting',
          icon: 'money-bill-alt',
          label: 'Paid',
        },
        printing: {
          date: 'Waiting',
          icon: 'print',
          label: '3D Printed',
        },
        shipping: {
          date: 'Waiting',
          icon: 'shipping-fast',
          label: 'Shipped',
        },
        delivered: {
          date: 'Waiting',
          icon: 'box-open',
          label: 'Received',
        },
      },
      productInfo: {
        completeSet: {
          amount: 0,
          src: 'complete-set.png',
          name: 'Complete Set',
          field: 'complete_set',
        },
        headAdapter: {
          amount: 0,
          src: 'head-adapter.png',
          name: 'Head Adapter',
          field: 'head_adapter',
        },
        tubeAdapter: {
          amount: 0,
          src: 'tube-adapter.png',
          name: 'Tube Adapter',
          field: 'tube_adapter',
        },
        tubeFixator: {
          amount: 0,
          src: 'tube-fixator.png',
          name: 'Tube Fixator',
          field: 'tube_fixator',
        },
        countersink: {
          amount: 0,
          src: 'countersink.png',
          name: 'Countersink',
          field: 'countersink',
        },
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      products: 'products/products',
    }),
  },
  mounted() {
    this.initProgress();
  },
  methods: {
    getImgURL,
    initProgress() {
      this.loading = true;

      const itemID = this.$route.params.orderId;
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          const db = firebase.firestore();
          const docRef = db.collection('orders').doc(itemID);
          docRef
            .get()
            .then((doc) => {
              if (doc.exists) {
                this.loading = false;

                this.updateOrderInformation(doc.data());
                this.updateProgressBar(doc.data().status);
              } else {
                // doc.data() will be undefined in this case
                console.log('No such document!');
                this.loading = false;
              }
            })
            .catch((error) => {
              console.log('Error getting document:', error);
              this.loading = false;
            });
        } else {
          this.$router.push('/login');
        }
      });
    },
    updateOrderInformation(data) {
      this.init3DModel(data.downloadurl);
      this.screw = {
        name: data.product_id,
        amount: data.amount,
      };

      this.productInfo.completeSet.amount = data.options.complete_set || 0;
      this.productInfo.headAdapter.amount = data.options.head_adapter || 0;
      this.productInfo.countersink.amount = data.options.countersink || 0;
      this.productInfo.tubeAdapter.amount = data.options.tube_adapter || 0;
      this.productInfo.tubeFixator.amount = data.options.tube_fixator || 0;

      // dates
      this.productTimeLine.confirmation.date = data.timestamp.toDate().toDateString() || 'Waiting';
      this.productTimeLine.payment.date = data.timestamp.toDate().toDateString() || 'Waiting';
      this.productTimeLine.printing.date = typeof data.status.printed !== 'undefined'
        ? data.status.printed.toDate().toDateString()
        : 'Waiting';
      this.productTimeLine.shipping.date = typeof data.status.shipped !== 'undefined'
        ? data.status.shipped.toDate().toDateString()
        : 'Waiting';
      // Shippo Tracking Updated Info
      // const delivered =
      // (typeof data.tracking_update.tracking_status.status || 'undefined') !== 'undefined'
      //   ? data.tracking_update.eta
      //   : 'Waiting';
    },
    updateProgressBar(status) {
      if (status.delivered && isTodayofBefore(status.delivered.toDate())) {
        this.updateStatus(100);
      } else if (status.shipped && isTodayofBefore(status.shipped.toDate())) {
        this.updateStatus(70);
      } else if (status.printed && isTodayofBefore(status.printed.toDate())) {
        this.updateStatus(50);
      } else if (status.paid && isTodayofBefore(status.paid.toDate())) {
        this.updateStatus(30);
      }
    },
    updateStatus(procent) {
      const progressBar = document.getElementById('progress-bar');
      // 10% Upload
      // 30% Create
      // 50% Send
      // 70% Print
      // 100% Delivered
      this.animate({
        duration: 1000,
        timing(timeFraction) {
          return timeFraction ** 5;
        },
        draw(progress) {
          progressBar.style.width = `${progress * procent}%`;
        },
      });
    },
    // Animate the progress bar
    animate({ duration, draw, timing }) {
      const start = performance.now();

      requestAnimationFrame(function animate(time) {
        let timeFraction = (time - start) / duration;
        if (timeFraction > 1) timeFraction = 1;

        const progress = timing(timeFraction);

        draw(progress);

        if (timeFraction < 1) {
          requestAnimationFrame(animate);
        }
      });
    },
    init3DModel(downloadurl) {
      this.downloadSTL(downloadurl);
    },
    downloadSTL(url) {
      document.getElementById('loading_container').style.display = 'flex';
      // $('#loading_container').style.display = 'flex';
      // This can be downloaded directly:
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = () => {
        const blob = xhr.response;
        const model = URL.createObjectURL(blob);
        this.STLViewer(model, 'model');
        document.getElementById('loading_container').style.display = 'none';
      };

      xhr.onerror = () => {
        // only triggers if the request couldn't be made at all
        // eslint-disable-next-line no-alert
        alert('Network Error');
        document.getElementById('loading_container').style.display = 'none';
      };

      xhr.onprogress = (event) => {
        // triggers periodically
        // event.loaded - how many bytes downloaded
        // event.lengthComputable = true if the server sent Content-Length header
        // event.total - total number of bytes (if lengthComputable)
        // console.log(`Received ${event.loaded} of ${event.total}`);
        document.getElementById('loading_porgress').innerText = `${(
          (event.loaded / event.total)
        * 100
        ).toFixed(0)}%`;
      };
      xhr.open('GET', url);
      xhr.send();
    },
    // ---> Model Viewer Functions <---
    STLViewer(model, elementID) {
      const elem = document.getElementById(elementID);
      const camera = new THREE.PerspectiveCamera(
        70,
        elem.clientWidth / elem.clientHeight,
        1,
        1000,
      );
      const renderer = new THREE.WebGLRenderer({
        alpha: true,
        antialias: true,
        transparent: true,
        preserveDrawingBuffer: true,
      });
      renderer.setSize(elem.clientWidth, elem.clientHeight);
      renderer.setClearColor(0x333333, 0);

      renderer.setPixelRatio(window.devicePixelRatio ? window.devicePixelRatio : 1);

      renderer.gammainput = true;
      renderer.gammaOutput = true;
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.cascade = true;
      renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.soft = true;
      renderer.localClippingEnabled = true;
      elem.appendChild(renderer.domElement);

      const controls = new THREE.OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.rotateSpeed = 0.05;
      controls.dampingFactor = 0.1;
      controls.enableZoom = true;
      controls.autoRotate = true;
      controls.autoRotateSpeed = 0.75;

      const scene = new THREE.Scene();
      scene.add(new THREE.HemisphereLight(0xffffff, 1.5));

      new THREE.STLLoader().load(model, (geometry) => {
        const material = new THREE.MeshPhongMaterial({
          color: 0xb4b4b4,
          specular: 100,
          shininess: 100,
        });
        const mesh = new THREE.Mesh(geometry, material);
        scene.add(mesh);

        const middle = new THREE.Vector3();
        geometry.computeBoundingBox();
        geometry.boundingBox.getCenter(middle);
        mesh.position.x = -1 * middle.x;
        mesh.position.y = -1 * middle.y;
        mesh.position.z = -1 * middle.z;

        const largestDimension = Math.max(
          geometry.boundingBox.max.x,
          geometry.boundingBox.max.y,
          geometry.boundingBox.max.z,
        );
        camera.position.z = largestDimension * 2.2;

        const animate = () => {
          requestAnimationFrame(animate);
          controls.update();
          renderer.render(scene, camera);
        };
        animate();
      });

      window.addEventListener(
        'resize',
        () => {
          renderer.setSize(elem.clientWidth, elem.clientHeight);
          camera.aspect = elem.clientWidth / elem.clientHeight;
          camera.updateProjectionMatrix();
        },
        false,
      );
    },
  },
};
</script>

<style src="./DetailOrder.scss" lang="scss" scoped />
