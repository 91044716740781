<template lang="pug">
  -
    const screwImg = '@/assets/images/screw.png'

  #shop
    div.mb-20(
      v-for="(category, id) in categories"
      :key="category.key"
    )
      div.title {{ category.label }}
      div.products(:key="productListKey")
        div.product-wrapper(
          v-for="(product) in category.products"
          :class="{'w-50': id === 2}"
          :key="product.id"
        )
          Product(
            :data="product"
            :adding="product.id === addingProduct"
            @addToCart="handleAddToCart"
          )
</template>

<script>
import { getImgURL } from '@/utils';
import Product from './components/Product.vue';
import { categories } from './constants';

export default {
  name: 'Shop',
  components: { Product },
  data() {
    return {
      categories,
      addingProduct: null,
      productListKey: 0,
    };
  },
  watch: {
    addingProduct: {
      immediate: true,
      handler() {
        this.productListKey += 1;
      },
    },
  },
  mounted() {
    this.$store.dispatch('shop/getStandardModelData');
  },
  methods: {
    getImgURL,
    gotoPage(name) {
      this.$router.push({ name });
    },
    handleAddToCart(productId) {
      if (productId === 'customised') {
        this.gotoPage('Configurator');
        return;
      }

      this.addingProduct = productId;
      this.$store.dispatch('shop/addItemToCart', productId)
        .then(() => {
          this.addingProduct = null;
        });
    },
  },
};
</script>

<style src="./Shop.scss" lang="scss" />
