<template lang="pug">
  div.terms-text
    | With registration, you are agreeing to our
    |
    a(
      href="http://www.r3volutiond.com/terms-conditions"
      target="_blank"
    ) Terms
    |
    | and
    |
    a(
      href="http://www.r3volutiond.com/terms-conditions"
      target="_blank"
    ) Privacy Policy
</template>
<script>
export default {
  name: 'TermsAndPolicy',
};
</script>
