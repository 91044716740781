<template lang="pug">
  #container
    Header
    #content
      TopBar
      router-view/
      //- div(style="word-break: break-all;") {{ user }}
    Footer
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/components/layout/Header';
import Footer from '@/components/layout/Footer';
import TopBar from '@/components/layout/TopBar';

export default {
  name: 'Container',
  components: {
    Header,
    Footer,
    TopBar,
  },
  computed: {
    ...mapGetters({
      user: 'authentication/user',
    }),
  },
  mounted() {
    this.$store.dispatch('products/fetchProducts');
  },
};
</script>

<style src="./Container.scss" lang="scss" scoped />
