<template lang="pug">
  div.step-container
    div.step-title
      | What kind of account?
    div.step-sub-title
      | Please let us know what type of account you're signing up for.

    div.account-type.flex-align-center(
      v-for="account in accountTypes"
      :key="account.type"
      :class="type === account.type && 'selected'"
      @click="handleSelectAccountType(account.type)"
    )
      div.image-wrapper
        img(
          :src="account.img"
          :alt="`account_${account.type}_img`"
        )
      | {{ account.label }}
</template>
<script>
import StepMixin from './StepMixin';

const ACCOUNT_TYPES = [
  {
    type: 0,
    label: 'Business Account',
    img: '/assets/images/icons8-company.png',
  },
  {
    type: 1,
    label: 'Personal Account',
    img: '/assets/images/icons8-customer.png',
  },
];

export default {
  name: 'Step1',
  mixins: [StepMixin],
  data() {
    return {
      accountTypes: ACCOUNT_TYPES,
    };
  },
  computed: {
    type() {
      return this.getData('type');
    },
  },
  methods: {
    handleSelectAccountType(type) {
      this.setData('type', type);
    },
  },
};
</script>

<style src="../Onboarding.scss" lang="scss" scoped />
