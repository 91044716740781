import store from '@/store';
import * as firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyByisG0mPeHPqSa7znEs0wJ5goP7hE3ooA',
  authDomain: 'r3volutiond.firebaseapp.com',
  databaseURL: 'https://r3volutiond.firebaseio.com',
  projectId: 'r3volutiond',
  storageBucket: 'r3volutiond.appspot.com',
  messagingSenderId: '613919818110',
  appId: '1:613919818110:web:9ab8a2858a750212',
};

firebase.initializeApp(firebaseConfig);

firebase.auth().onAuthStateChanged((user) => {
  store.dispatch('authentication/fetchUser', user);
  console.log('> onAuthStateChanged', store.state);
});
