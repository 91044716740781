import getters from './getters';
import mutations from './mutations';
import actions from './actions';

export const state = {
  user: {
    loggedIn: false,
    data: null,
  },
  loading: false,
  error: '',
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
