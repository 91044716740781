<template lang="pug">
  div.step-container
    div.step-title
      | What is your address?
    div.step-sub-title
      | Please let us know your invoice address and shipping address.

    div.step-content
      div.form__input
        base-address-auto-complete(
          label="Enter your invoice address"
          placeholder="Enter street and it suggests you addresses"
          :value="address"
          id="address"
          @change="val => handleAddressChange('address')(val)"
        )
        base-address-auto-complete(
          label="Enter your shipping address"
          placeholder="Enter street and it suggests you addresses"
          :value="shippingAddress"
          id="shipping-address"
          @change="val => handleAddressChange('shipping_address')(val)"
        )

</template>
<script>
import StepMixin from './StepMixin';

export default {
  name: 'Step3',
  mixins: [StepMixin],
  data() {
    return {
      addressAutoComplete: null,
      shippingAddressAutoComplete: null,
    };
  },
  computed: {
    address() {
      const addr = this.getData('address');
      if (!addr || !addr.street) {
        return '';
      }

      return this.addressTemplate(addr);
    },
    shippingAddress() {
      const addr = this.getData('shipping_address');
      if (!addr || !addr.street) {
        return '';
      }

      return this.addressTemplate(addr);
    },
  },
  methods: {
    addressTemplate({
      street, city, zip, country,
    }) {
      return `${street}, ${city}, ${zip}, ${country}`;
    },
    handleAddressChange(key) {
      return (suggestion) => {
        this.setData(key, suggestion);
      };
    },
  },
};
</script>

<style src="../Onboarding.scss" lang="scss" scoped />
