<template lang="pug">
  #option
    .screw-container
      div#model.model-viwer
        div#loading_container.loading-container
          div.loading-wrapper
            base-animation(name='loading-dark')
          div#loading_porgress.loading-text
            | 0%
    div.option-panel-wrapper
      div.option-panel
        base-card.product.ml-auto
          div.title Your Custom Product
          div.product-info
            div.info
              span.header Info
              div.desc {{ screw.name }}
            div
              span.header   Price excl.VAT
              div.desc {{ screw.price }}
            div.amount
              span.header Amount
              base-input(
                :value.sync="screw.amount"
                :min="productInfo.completeSet.amount"
                wrapperClass="amount"
                type="number"
              )
        base-card.options.ml-auto
          div.title Choose options
          div.option(v-for="(elem, elemIndex) in productInfo" :key="elemIndex")
            div.flex-align-center
              img.option-image(:src="getImgURL(elem.src)")
              span {{ elem.name }}
            div.price.flex-align-center {{ elem.price }}
            base-input(
              :value="elem.amount"
              :max="elemIndex === 'completeSet' ? screw.amount : undefined"
              wrapperClass="amount"
              type="number"
              @update:value="(val) => handleProductAmountChange(elemIndex, val)"
            )
          div.checkout-button
            base-button(
              id="checkoutButton"
              label="Proceed to Checkout"
              :busy="isProceeding"
              full-width
              @onClick="checkout"
            )
</template>

<script>
import firebase from 'firebase';
import * as THREE from '@/vendors/lib/three.min';
import { getImgURL } from '@/utils';

export default {
  name: 'Option',
  data() {
    return {
      screw: {
        price: 0,
        amount: 1,
        name: '',
      },
      productInfo: {
        completeSet: {
          price: 0,
          amount: 0,
          src: 'complete-set.png',
          name: 'Complete Set',
          field: 'complete_set',
        },
        headAdapter: {
          price: 0,
          amount: 0,
          src: 'head-adapter.png',
          name: 'Head Adapter',
          field: 'head_adapter',
        },
        tubeAdapter: {
          price: 0,
          amount: 0,
          src: 'tube-adapter.png',
          name: 'Tube Adapter',
          field: 'tube_adapter',
        },
        tubeFixator: {
          price: 0,
          amount: 0,
          src: 'tube-fixator.png',
          name: 'Tube Fixator',
          field: 'tube_fixator',
        },
        countersink: {
          price: 0,
          amount: 0,
          src: 'countersink.png',
          name: 'Countersink',
          field: 'countersink',
        },
      },
      order: '',
      isProceeding: false,
    };
  },
  mounted() {
    this.initApp();
  },
  methods: {
    handleProductAmountChange(key, amount) {
      this.productInfo[key].amount = amount;
    },
    getImgURL,
    initApp() {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          const { uid } = firebase.auth().currentUser;
          this.getCustomerOrder(uid);
          this.getProductInformation();
        }
      });
    },
    getCustomerOrder(uid) {
      const db = firebase.firestore();
      db.collection('users')
        .doc(uid)
        .collection('cart')
        .orderBy('timestamp', 'desc')
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            // We know there is one doc in the querySnapshot
            const queryDocumentSnapshot = snapshot.docs[0];
            this.order = {
              snapshot_id: queryDocumentSnapshot.id,
              downloadurl: queryDocumentSnapshot.data().downloadurl,
              filename: queryDocumentSnapshot.data().product_id,
              timestamp: queryDocumentSnapshot.data().timestamp,
            };
            this.init3DModel(queryDocumentSnapshot.data().downloadurl);
            this.screw.name = this.order.filename;
          } else {
            // eslint-disable-next-line no-alert
            alert('Nothing in your Cart. Return to Products');
          }
        });
    },
    getProductInformation() {
      const productInfo = {};
      firebase
        .firestore()
        .collection('products')
        .doc('titanium_screw')
        .collection('prices')
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            productInfo[doc.id] = doc.data();
          });
          if (this.order.filename.includes('Standard')) {
            this.screw.price = `€ ${productInfo.screw.amount.toFixed(2)}`;
            this.productInfo.completeSet.price = `€ ${productInfo.screw.amount.toFixed(2)}`;
          } else {
            this.screw.price = `€ ${productInfo.custom_screw.amount.toFixed(2)}`;
            this.productInfo.completeSet.price = `€ ${productInfo.custom_set.amount.toFixed(2)}`;
          }

          this.productInfo.headAdapter.price = `€ ${productInfo.head_adapter.amount.toFixed(2)}`;
          this.productInfo.tubeAdapter.price = `€ ${productInfo.tube_adapter.amount.toFixed(2)}`;
          this.productInfo.tubeFixator.price = `€ ${productInfo.tube_fixator.amount.toFixed(2)}`;
          this.productInfo.countersink.price = `€ ${productInfo.countersink.amount.toFixed(2)}`;
        });
    },

    // Check what checkout we got/ Standard Model/ Individual Model
    checkout() {
      this.isProceeding = true;

      document.getElementById('checkoutButton').style.pointerEvents = 'none';

      let screwAmount = this.screw.amount;
      if (screwAmount === '') {
        screwAmount = 1;
      }
      let headAdapterAmount = this.productInfo.headAdapter.amount;
      if (headAdapterAmount === '') {
        headAdapterAmount = 0;
      }
      let tubeAdapterAmount = this.productInfo.tubeAdapter.amount;
      if (tubeAdapterAmount === '') {
        tubeAdapterAmount = 0;
      }
      let tubeFixatorAmount = this.productInfo.tubeFixator.amount;
      if (tubeFixatorAmount === '') {
        tubeFixatorAmount = 0;
      }
      let countersinkAmount = this.productInfo.countersink.amount;
      if (countersinkAmount === '') {
        countersinkAmount = 0;
      }
      let completeSetAmount = this.productInfo.completeSet.amount;
      if (completeSetAmount === '') {
        completeSetAmount = 0;
      }
      const db = firebase.firestore();
      // Get the current user
      const user = firebase.auth().currentUser;

      // Upload to cart
      db.collection('users')
        .doc(user.uid)
        .collection('cart')
        .doc(this.order.snapshot_id)
        .update({
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          amount: screwAmount,
          options: {
            complete_set: completeSetAmount,
            head_adapter: headAdapterAmount,
            tube_adapter: tubeAdapterAmount,
            tube_fixator: tubeFixatorAmount,
            countersink: countersinkAmount,
          },
        })
        .then(() => {
          this.isProceeding = false;
          this.$router.push({ name: 'Checkout' });
        })
        .catch((error) => {
          this.isProceeding = false;
          console.error('Error writing document: ', error.code);
          // eslint-disable-next-line no-alert
          alert('Something went wrong. Please try again or contact support.');
        });
    },
    init3DModel(downloadurl) {
      this.downloadSTL(downloadurl);
    },
    downloadSTL(url) {
      document.getElementById('loading_container').style.display = 'flex';
      // $('#loading_container').style.display = 'flex';
      // This can be downloaded directly:
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = () => {
        const blob = xhr.response;
        const model = URL.createObjectURL(blob);
        this.STLViewer(model, 'model');
        document.getElementById('loading_container').style.display = 'none';
      };

      xhr.onerror = () => {
        // only triggers if the request couldn't be made at all
        // eslint-disable-next-line no-alert
        alert('Network Error');
        document.getElementById('loading_container').style.display = 'none';
      };

      xhr.onprogress = (event) => {
        // triggers periodically
        // event.loaded - how many bytes downloaded
        // event.lengthComputable = true if the server sent Content-Length header
        // event.total - total number of bytes (if lengthComputable)
        // console.log(`Received ${event.loaded} of ${event.total}`);
        document.getElementById('loading_porgress').innerText = `${(
          (event.loaded / event.total)
      * 100
        ).toFixed(0)}%`;
      };
      xhr.open('GET', url);
      xhr.send();
    },
    // ---> Model Viewer Functions <---
    STLViewer(model, elementID) {
      const elem = document.getElementById(elementID);
      const camera = new THREE.PerspectiveCamera(
        70,
        elem.clientWidth / elem.clientHeight,
        1,
        1000,
      );
      const renderer = new THREE.WebGLRenderer({
        alpha: true,
        antialias: true,
        transparent: true,
        preserveDrawingBuffer: true,
      });
      renderer.setSize(elem.clientWidth, elem.clientHeight);
      renderer.setClearColor(0x333333, 0);

      renderer.setPixelRatio(window.devicePixelRatio ? window.devicePixelRatio : 1);

      renderer.gammainput = true;
      renderer.gammaOutput = true;
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.cascade = true;
      renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.soft = true;
      renderer.localClippingEnabled = true;
      elem.appendChild(renderer.domElement);

      const controls = new THREE.OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.rotateSpeed = 0.05;
      controls.dampingFactor = 0.1;
      controls.enableZoom = true;
      controls.autoRotate = true;
      controls.autoRotateSpeed = 0.75;

      const scene = new THREE.Scene();
      scene.add(new THREE.HemisphereLight(0xffffff, 1.5));

      new THREE.STLLoader().load(model, (geometry) => {
        const material = new THREE.MeshPhongMaterial({
          color: 0xb4b4b4,
          specular: 100,
          shininess: 100,
        });
        const mesh = new THREE.Mesh(geometry, material);
        scene.add(mesh);

        const middle = new THREE.Vector3();
        geometry.computeBoundingBox();
        geometry.boundingBox.getCenter(middle);
        mesh.position.x = -1 * middle.x;
        mesh.position.y = -1 * middle.y;
        mesh.position.z = -1 * middle.z;

        const largestDimension = Math.max(
          geometry.boundingBox.max.x,
          geometry.boundingBox.max.y,
          geometry.boundingBox.max.z,
        );
        camera.position.z = largestDimension * 2.5;

        /* Make it always fit to object */
        // const fov = camera.fov * (Math.PI / 180);
        // let cameraZ = Math.abs((largestDimension / 4) * Math.tan(fov * 2));

        // cameraZ *= 1.25;

        // camera.position.z = cameraZ;

        // const minZ = geometry.boundingBox.min.z;
        // const cameraToFarEdge = (minZ < 0) ? -minZ + cameraZ : cameraZ - minZ;

        // camera.far = cameraToFarEdge * 3;
        // camera.updateProjectionMatrix();

        // if (controls) {
        //   controls.target = middle;
        //   controls.maxDistance = cameraToFarEdge * 2;

        //   controls.saveState();
        // }

        const animate = () => {
          requestAnimationFrame(animate);
          controls.update();
          renderer.render(scene, camera);
        };
        animate();
      });

      window.addEventListener(
        'resize',
        () => {
          renderer.setSize(elem.clientWidth, elem.clientHeight);
          camera.aspect = elem.clientWidth / elem.clientHeight;
          camera.updateProjectionMatrix();
        },
        false,
      );
    },
  },
};
</script>

<style src="./Option.scss" lang="scss" scoped />
