import firebase from 'firebase';

const actions = {
  nextStep({ commit, state }) {
    const { data } = state;

    // Step validation
    if (state.currentStep === 1) {
      if (data.type !== 0 && data.type !== 1) {
        commit('SET_ERROR', 'Please select account type.');
        return;
      }
    }

    if (state.currentStep === 2) {
      if (!data.name) {
        commit('SET_ERROR', 'Name is required.');
        return;
      }
      if (data.type === 0 && !data.vat) {
        commit('SET_ERROR', 'VAT is required.');
        return;
      }
    }

    if (state.currentStep === 3) {
      if (
        !data.address
        || !data.shipping_address
        || !data.address.street
        || !data.shipping_address.street
      ) {
        commit('SET_ERROR', 'Address is required.');
        return;
      }
    }

    commit('SET_ERROR', '');
    commit('NEXT_STEP');
  },
  prevStep({ commit }) {
    commit('SET_ERROR', '');
    commit('PREV_STEP');
  },
  async submit({ commit, state }) {
    commit('SET_LOADING_STATUS', true);
    commit('SET_ERROR', '');

    // if (!firebase.auth().currentUser) {
    //   // Redirect to login if not authenticated
    //   window.location.href = '/login';
    // }

    try {
      const { uid } = firebase.auth().currentUser;
      const { email } = firebase.auth().currentUser;
      const db = firebase.firestore();
      const { data } = state;

      // store data
      await db.collection('users')
        .doc(uid)
        .set(
          {
            ...data,
            uid,
            email,
            interests:
              (data.interests
              && Object.keys(data.interests).map((key) => data.interests[key])) || [],
            onboarded: true,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          },
          {
            merge: true,
          },
        );

      // success, send emails
      const sendWelcomeEmail = firebase.functions().httpsCallable('auth-sendWelcomeEmail');
      sendWelcomeEmail({
        email,
        displayName: data.name,
        account: data.type,
      });

      // send verification email
      await firebase
        .auth()
        .currentUser.sendEmailVerification();

      window.location.replace('/verify-email');
    } catch (err) {
      commit('SET_ERROR', err.message || 'An error occurred.');
    } finally {
      commit('SET_LOADING_STATUS', false);
    }
  },
};

export default actions;
