/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
/* eslint-disable import/prefer-default-export */
export const getImgURL = (name) => require(`@/assets/images/${name}`);
export const getAbsoluteImgUrl = (name) => `/assets/images/${name}`;

export const isTodayofBefore = (someDate) => {
  const today = new Date();
  return someDate <= today;
};
