import firebase from 'firebase';

const DEFAULT_FETCH_COUNT = 10;

const actions = {
  fetchProducts({ commit, state }) {
    commit('SET_LOADING_STATUS', true);

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const { uid } = user;
        const db = firebase.firestore();
        let query = db.collection('users')
          .doc(uid)
          .collection('orders')
          .orderBy('timestamp', 'desc');

        if (state.lastVisible) {
          query = query.startAfter(state.lastVisible);
        }

        query
          .limit(DEFAULT_FETCH_COUNT)
          .get()
          .then(async (querySnapshot) => {
            let { products } = state;

            // set states for next load
            if (querySnapshot.size < DEFAULT_FETCH_COUNT) {
              commit('SET_HAS_MORE', false);
            } else {
              commit('SET_HAS_MORE', true);
            }
            commit('SET_LAST_VISIBLE', querySnapshot.docs[querySnapshot.docs.length - 1]);

            const docs = await Promise.all(
              querySnapshot.docs.map((doc) => db.collection('orders')
                .doc(doc.id)
                .get()
                .then((snapshot) => ({
                  [doc.id]: snapshot.data() || doc.data(),
                }))),
            );
            docs.forEach((doc) => {
              products = { ...products, ...doc };
            });

            commit('SET_PRODUCTS', products);
            commit('SET_LOADING_STATUS', false);
          },
          (error) => {
            console.log(`Error: ${error}`);
            commit('SET_LOADING_STATUS', false);
          });
      }
    });
  },
};

export default actions;
