<template lang="pug">
  #checkout
    base-card.checkout-card(
      :title="title"
    )
      div.step-1(v-if="step === 1" :key="key")
        base-input.mt-space(
          label="Name"
          placeholder="Name"
          :value.sync="name"
          full-width
        )
        base-input.mt-space(
          label="VAT Number"
          placeholder="VAT Number"
          :value.sync="vatNumber"
          full-width
        )
        base-input.mt-space(
          label="Invoice Address"
          placeholder="Street and Number"
          :value.sync="invoiceAddress.street"
          full-width
        )
        div.group
          base-input.width-sticky.mr-space(
            placeholder="Zip"
            :value.sync="invoiceAddress.zip"
            full-width
          )
          base-input.width-50.mr-space(
            placeholder="City"
            :value.sync="invoiceAddress.city"
            full-width
          )
          base-input.width-50(
            placeholder="Country"
            :value.sync="invoiceAddress.country"
            full-width
          )

        base-input.mt-space(
          label="Shipping Address"
          placeholder="Street and Number"
          :value.sync="shippingAddress.street"
          full-width
        )
        div.group
          base-input.width-sticky.mr-space(
            placeholder="Zip"
            :value.sync="shippingAddress.zip"
            full-width
          )
          base-input.width-50.mr-space(
            placeholder="City"
            :value.sync="shippingAddress.city"
            full-width
          )
          base-input.width-50(
            placeholder="Country"
            :value.sync="shippingAddress.country"
            full-width
          )
        base-button.next-button(label="Continue" @onClick="saveData")
      div.step-2(v-show="step === 2")
        #paymentInfo.paymentInfo
          .card-logo
            .credit-card-default Default
            #credit_card_logo.credit-card-logo {{ cardLogo }}
              //- v-icon(name="cc-visa" scale="1.5")
          .card-number
            #credit_card_number.credit-card-number {{ cardNumber }}
          .card-expiry
            #credit_card_name.credit-card-name {{ cardName }}
            #credit_card_expiry.credit-card-expiry-date {{ cardExp }}
        #credit_card_form.credit-card-form(
          v-show="showCardElement === true"
        )
          .login-label Card Number
          .w-embed
            form#payment-form(action="/charge" method="post")
              .form-row
                #card-element
                #card-errors
          .profile-row
            .field-wrapper.first-name-wrapper
              .login-label Name on Card
              base-input(
                id="stripe-name"
                class="profile-input-field"
                full-width
              )
          #paymentError.error-message
            | This is some text inside of a div block.
        a#change_card_link.change-credit-card-link(
          @click="toggleCardElement"
        )
          | Change
        base-button.next-button(label="Continue" @onClick="paymentSaveData")
</template>

<script src="https://js.stripe.com/v3/"></script>
<script>
import firebase from 'firebase';

export default {
  name: 'Checkout',
  data() {
    return {
      title: 'Check your information',

      // Address
      key: 0,
      step: 1,
      showCardElement: false,
      name: '',
      vatNumber: '',
      invoiceAddress: {
        street: '',
        city: '',
        country: '',
        zip: '',
      },
      shippingAddress: {
        street: '',
        city: '',
        country: '',
        zip: '',
      },

      // Payment
      stripe: null,
      elements: null,
      card: null,
      paymentCustomerData: {},
      newCard: false,
      cardName: 'Name',
      cardLogo: '',
      cardNumber: '•••• •••• •••• 0000',
      cardExp: '00/00',
    };
  },
  mounted() {
    this.initApp();
  },
  methods: {
    toggleCardElement() {
      this.showCardElement = !this.showCardElement;
      if (this.newCard == false) {
        document.getElementById("change_card_link").innerHTML = "Cancel";
        document.getElementById("paymentInfo").style.display = "none";
        document.getElementById("credit_card_form").style.display = "block";
        this.newCard = true;
      } else {
        this.card.clear();
        document.getElementById("change_card_link").innerHTML = "Change";
        document.getElementById("paymentInfo").style.display = "flex";
        document.getElementById("credit_card_form").style.display = "none";
        this.newCard = false;
      }
    },
    updateStep(val) {
      this.step = val;
      switch (val) {
        case 2:
          this.title = 'Choose a payment method';
          this.initPayment();
          break;
        case 3:
          this.title = 'Check your information';
          break;
        case 1:
        default:
          this.title = 'Check your information';
          break;
      }
    },
    initApp() {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          const { uid } = firebase.auth().currentUser;
          this.getCustomerData(uid);
        }
      });
    },
    getCustomerData(uid) {
      const db = firebase.firestore();
      const docRef = db.collection('users').doc(uid);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.name = doc.data().name || '';
            this.vatNumber = doc.data().vat || '';
            this.invoiceAddress = doc.data().address || {
              street: '',
              city: '',
              country: '',
              zip: '',
            };
            this.shippingAddress = doc.data().shipping_address || {
              street: '',
              city: '',
              country: '',
              zip: '',
            };
            this.key += 1;
          } else {
            console.log('No such document!');
          }
        })
        .catch((error) => {
          console.log('Error getting document:', error);
        });
    },
    saveData() {
      // activateLoading();
      const db = firebase.firestore();
      const { uid } = firebase.auth().currentUser;
      const usersRef = db.collection('users').doc(uid);
      // Set the update of the profile
      return usersRef
        .set(
          {
            name: this.name,
            vat: this.vatNumber,
            address: this.invoiceAddress,
            shipping_address: this.shippingAddress,
          },
          { merge: true },
        )
        .then(() => {
          console.log('Document successfully updated!');
          // deactivateLoading('Continue');
          this.updateStep(2);
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(`Error code: ${errorCode}`);
          console.log(`Error message: ${errorMessage}`);
          // deactivateLoading('Continue');

          // eslint-disable-next-line no-alert
          alert(errorMessage);
          console.error('Error updating document: ', error);
        });
    },
    initPayment() {
      this.stripe = Stripe('pk_test_15zcp0Vn5kJWr1vxhF78IrOC00tEE3wSdV');
      this.elements = this.stripe.elements();
      this.card = this.elements.create('card');
      this.card.mount('#card-element');
      this.getPaymentCustomerData();
      this.getCustomerStripeSources();
    },
    paymentSaveData() {
      if (this.newCard === false) {
        // The setup has succeeded. Display a success message.

        this.$router.push({ name: 'Confirmation' });
        // this.updateStep(3);
      } else {
        this.submitNewCreditCard();
      }
    },
    getPaymentCustomerData() {
      const { uid } = firebase.auth().currentUser;

      firebase
        .firestore()
        .collection('users')
        .doc(uid)
        .onSnapshot((snapshot) => {
          if (snapshot.data()) {
            this.paymentCustomerData = snapshot.data();
            // startDataListeners();
          } else {
            console.warn(
              `No Stripe customer found in Firestore for user: ${uid}`,
            );
          }
        });
    },
    getCustomerStripeSources() {
      const { uid } = firebase.auth().currentUser;

      /**
       * Get all payment methods for the logged in customer
       */
      firebase
        .firestore()
        .collection('users')
        .doc(uid)
        .collection('payment_methods')
        .orderBy('created', 'desc')
        .limit(1)
        .onSnapshot((snapshot) => {
          if (snapshot.empty) {
            document.getElementById('change_card_link').style.display = 'none';
            document.getElementById('paymentInfo').style.display = 'none';
            document.getElementById('credit_card_form').style.display = 'block';
            this.newCard = true;
          }
          snapshot.forEach((doc) => {
            const paymentMethod = doc.data();
            if (!paymentMethod.card) {
              return;
            }
            this.cardName = paymentMethod.billing_details.name;
            const paymentData = paymentMethod.card;
            // TODO
            // paymentInfo.style.display = 'flex';
            // credit_card_form.style.display = 'none';
            switch (paymentData.brand) {
              case 'mastercard':
                this.cardLogo = 'MasterCard';
                break;
              case 'american_express':
                this.cardLogo = 'American Express';
                break;
              default:
                this.cardLogo = '';
            }
            this.cardNumber = `•••• •••• •••• ${paymentData.last4}`;
            this.cardExp = `${paymentData.exp_month}/${paymentData.exp_year}`;
          });
        });
    },
    submitNewCreditCard() {
      const { uid } = firebase.auth().currentUser;

      // activateLoading();
      if (document.getElementById('stripe-name').value === undefined
        || document.getElementById('stripe-name').value === ''
      ) {
        // Display error.message in your UI.
        // deactivateLoading('Continue');
        // Inform the user if there was an error.
        const errorElement = document.getElementById('paymentError');
        errorElement.style.display = 'block';
        errorElement.textContent = 'Please add the name that is on the card.';
        return;
      }

      this.stripe
        .confirmCardSetup(this.paymentCustomerData.setup_secret, {
          payment_method: {
            card: this.card,
            billing_details: {
              name: document.getElementById('stripe-name').value,
            },
          },
        })
        .then((result) => {
          if (result.error) {
            // Display error.message in your UI.
            console.log('Error', result.error);
            // deactivateLoading('Continue');
            // Inform the user if there was an error.
            const errorElement = document.getElementById('paymentError');
            errorElement.style.display = 'block';
            errorElement.textContent = result.error.message;
          } else {
            firebase
              .firestore()
              .collection('users')
              .doc(uid)
              .collection('payment_methods')
              .add({ id: result.setupIntent.payment_method, default: true })
              .then(() => {
                // The setup has succeeded. Display a success message.

                this.$router.push({ name: 'Confirmation' });

                // window.location.replace('/dashboard/checkout/confirmation');
              })
              .catch((error) => {
                // Display error.message in your UI.
                // deactivateLoading('Continue');
                // Inform the user if there was an error.
                const errorElement = document.getElementById('paymentError');
                errorElement.style.display = 'block';
                errorElement.textContent = error.message;
              });
          }
        });
      var setupIntent = firebase.functions().httpsCallable("stripe-setupIntent");
      setupIntent()
        .then(function (result) {
          console.log("Created Setup Intent", result.data);
          // Read result of the Cloud Function.
          stripe
            .confirmCardSetup(result.data, {
              payment_method: {
                card: card,
                billing_details: {
                  name: document.getElementById("stripe-name").value,
                },
              },
            })
            .then(function (result) {
              if (result.error) {
                // Display error.message in your UI.
                console.log("Error", result.error);
                deactivateLoading("Continue");
                // Inform the user if there was an error.
                var errorElement = document.getElementById("paymentError");
                errorElement.textContent = result.error.message;
              } else {
                var saveCard = firebase
                  .functions()
                  .httpsCallable("stripe-saveCardtoCustomer");
                saveCard({
                  intent: result.setupIntent,
                  old: payment_id,
                })
                  .then(function (result) {
                    // The setup has succeeded. Display a success message.

                    this.$router.push({ name: 'Confirmation' });

                    // window.location.replace("/dashboard/checkout/confirmation");
                  })
                  .catch(function (error) {
                    // Display error.message in your UI.
                    deactivateLoading("Continue");
                    // Inform the user if there was an error.
                    var errorElement = document.getElementById("paymentError");
                    errorElement.textContent = error.message;
                  });
              }
            });
        })
        .catch(function (error) {
          // Getting the Error details.
          deactivateLoading("Continue");
          var code = error.code;
          var message = error.message;
          var details = error.details;
          console.log("Error code: " + code);
          console.log("Error message: " + message);
          var errorElement = document.getElementById("paymentError");
          errorElement.textContent = error.message;
        });
    },
  },
};
</script>

<style src="./Checkout.scss" lang="scss" scoped />
