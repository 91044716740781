/* eslint-disable */
import $ from 'jquery';
import firebase from 'firebase';


const pxToCenRatio = 37.79527559055118;

const bone3DViewer = function () {

  const main = this;

  main.camera_x = 0;
  main.camera_y = 0;
  main.camera_z = 100;

  main.camera_lx = 0;
  main.camera_ly = 0;
  main.camera_lz = 0;

  // Assets
  main.font = null;
  main.head_small = null;
  main.head_mediumn = null;
  main.head_large = null;

  main.bot_thread = null;
  main.mid_thread = null;
  main.top_thread = null;

  main.shaft_empt_small = null;
  main.shaft_empt_mediumn = null;
  main.shaft_empt_large = null;

  main.shaft_min = null;
  main.shaft_mediumn = null;
  main.shaft_max = null;

  main.obj_main = null;

  main.dragArr = [];

  main.ctrlMode = "rotate";
  main.ismeasure = false;
  main.zRatio = 0;

  // type: small, mediumn, large
  main.porsType = "small";
  main.porsNum = 0;
  main.size_width = 0;
  main.size_head = 7;
  main.size_shaft = 20;
  main.size_thread = 15;

  // calc;
  main.total_shaft = 0;

  main.obj_header = null;
  main.obj_thread = null;
  main.obj_shaft = null;
  main.obj_text_header = null;

  main.head_size = new THREE.Vector3();
  main.bot_size = new THREE.Vector3();
  main.mid_size = new THREE.Vector3();
  main.top_size = new THREE.Vector3();

  main.pType = null;
  main.porsNum = null;
  main.sLenght = null;
  main.tLenght = null;

  main.exportString = "";

  //******************************************************
  // Init function
  //******************************************************
  main.fnInit = function () {
    main.elemID = "area_3d";
    main.clock = new THREE.Clock();

    main.fnInitScene();
    main.fnInitCamera();
    main.fnInitRenderer();
    main.fnInitLights();
    main.fnInitElement();
    main.fnInitControl();
    main.fnInitEvent();
    main.fnRender();
  };

  //******************************************************
  // Initialize 3D Scene
  //******************************************************
  main.fnInitScene = function () {
    main.scene = new THREE.Scene();
  };

  //******************************************************
  // Initialize Camera
  //******************************************************
  main.fnInitCamera = function () {
    main.screen_width = document.getElementById(main.elemID).clientWidth;
    main.screen_height = document.getElementById(main.elemID).clientHeight;

    const w = (main.screen_width / pxToCenRatio) * 10;
    const h = (main.screen_height / pxToCenRatio) * 10;

    main.camera = new THREE.OrthographicCamera(
      w / -2,
      w / 2,
      h / 2,
      h / -2,
      1,
      1000
    );
    main.scene.add(main.camera);

    main.camera.position.set(main.camera_x, main.camera_y, main.camera_z);
    main.camera.lookAt(
      new THREE.Vector3(main.camera_lx, main.camera_ly, main.camera_lz)
    );

    main.camera.zoom = 1;
    main.camera.updateProjectionMatrix();
  };

  //******************************************************
  // Initialize Control
  //******************************************************
  main.fnInitControl = function () {
    // main.orbitControls = new THREE.OrbitControls(main.camera, main.webGLRenderer.domElement, new THREE.Vector3(main.camera_lx, main.camera_ly, main.camera_lz));
    // main.orbitControls.rotateSpeed = 0.5;
    // main.orbitControls.zoomSpeed = 1.5;
    // main.orbitControls.panSpeed = 0.8;
    // main.orbitControls.enablePan = false;
    // main.orbitControls.enableDamping = true;
    // main.orbitControls.dampingFactor = 0.4;

    main.dragArr = [];
    main.dragControls = new THREE.DragControls(
      main.dragArr,
      main.camera,
      main.webGLRenderer.domElement
    );
    main.dragControls.addEventListener("dragstart", function () {
      main.ctrlMode = "drag";
    });
    main.dragControls.addEventListener("dragend", function () {
      main.ctrlMode = "rotate";
    });
  };

  //******************************************************
  // Initialize Rendere
  //******************************************************
  main.fnInitRenderer = function () {
    main.webGLRenderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: true,
      transparent: true,
      preserveDrawingBuffer: true,
    });
    // main.webGLRenderer = new THREE.WebGLRenderer({ antialias: true, alpha : true, transparent : true });
    main.webGLRenderer.setSize(main.screen_width, main.screen_height);
    main.webGLRenderer.setClearColor(0x333333, 0);

    main.webGLRenderer.setSize(main.screen_width, main.screen_height);
    main.webGLRenderer.setPixelRatio(
      window.devicePixelRatio ? window.devicePixelRatio : 1
    );

    main.webGLRenderer.gammainput = true;
    main.webGLRenderer.gammaOutput  = true;
    main.webGLRenderer.shadowMap.enabled = true;
    main.webGLRenderer.shadowMap.cascade = true;
    main.webGLRenderer.shadowMap.type = THREE.PCFSoftShadowMap;
    main.webGLRenderer.shadowMap.enabled = true;
    main.webGLRenderer.shadowMap.soft = true;
    main.webGLRenderer.localClippingEnabled = true;
  };

  //******************************************************
  // Initialize Events
  //******************************************************
  main.fnInitEvent = function () {
    window.addEventListener(
      "resize",
      function () {
        main.screen_width = document.getElementById(main.elemID).clientWidth;
        main.screen_height = document.getElementById(main.elemID).clientHeight;

        main.camera.aspect = main.screen_width / main.screen_height;
        main.camera.updateProjectionMatrix();
        main.webGLRenderer.setSize(main.screen_width, main.screen_height);
        main.webGLRenderer.setPixelRatio(
          window.devicePixelRatio ? window.devicePixelRatio : 1
        );
      },
      false
    );

    document
      .getElementById(main.elemID)
      .addEventListener("mousedown", function (e) {
        main.isDragging = true;

        main.initMousePosition = {
          x: e.offsetX,
          y: e.offsetY,
        };

        main.previousMousePosition = {
          x: e.offsetX,
          y: e.offsetY,
        };
      });

    document
      .getElementById(main.elemID)
      .addEventListener("mousemove", function (e) {
        if (main.ismeasure) {
          return;
        }

        if (!main.isDragging) {
          return;
        }

        if (main.ctrlMode != "rotate") return;

        if (!main.obj_main) return;

        const realX =
          main.screen_width / 2 +
          (main.obj_main.position.x * pxToCenRatio) / 10;
        const realY =
          main.screen_height / 2 -
          (main.obj_main.position.y * pxToCenRatio) / 10;

        // console.log(realX);
        // console.log(realY);

        const deltaMove = {
          x: e.offsetX - realX,
          y: e.offsetY - realY,
        };

        if (main.isDragging) {
          let atan = Math.atan2(deltaMove.y, deltaMove.x);
          main.obj_main.rotation.z = Math.PI / 2 - atan;

          // // test
          // const deltaMove = {
          //     x: e.offsetX - main.previousMousePosition.x,
          //     y: e.offsetY - main.previousMousePosition.y
          // };

          // const deltaRotationQuaternion = new THREE.Quaternion()
          // deltaRotationQuaternion.setFromEuler(new THREE.Euler((deltaMove.y / 180 * Math.PI), (deltaMove.x / 180 * Math.PI), 0, 'YXZ'));
          // // deltaRotationQuaternion.setFromEuler(new THREE.Euler(0, 0, (deltaMove.y / 180 * Math.PI), 'YXZ'));
          // main.obj_main.quaternion.multiplyQuaternions(deltaRotationQuaternion, main.obj_main.quaternion);
        }

        main.previousMousePosition = {
          x: e.offsetX,
          y: e.offsetY,
        };
      });

    document
      .getElementById("overflow-path")
      .addEventListener("mousedown", function (e) {
        main.initMousePosition = {
          x: e.offsetX,
          y: e.offsetY,
        };

        main.ismeasure_move = true;
      });

    document
      .getElementById("overflow-path")
      .addEventListener("mousemove", function (e) {
        if (main.ismeasure_move) {
          const canvas = document.getElementById("overflow-path");
          const ctx = canvas.getContext("2d");

          ctx.clearRect(0, 0, canvas.width, canvas.height);

          ctx.beginPath();
          ctx.moveTo(main.initMousePosition.x, main.initMousePosition.y);
          ctx.lineTo(e.offsetX, e.offsetY);
          ctx.lineWidth = 2;
          ctx.strokeStyle = "#00FF00";
          ctx.stroke();
          ctx.closePath();
        }
      });

    document
      .getElementById("overflow-path")
      .addEventListener("mouseup", function (e) {
        if (main.ismeasure || main.ismeasure_move) {
          const canvas = document.getElementById("overflow-path");
          const ctx = canvas.getContext("2d");

          ctx.clearRect(0, 0, canvas.width, canvas.height);

          ctx.beginPath();
          ctx.moveTo(main.initMousePosition.x, main.initMousePosition.y);
          ctx.lineTo(e.offsetX, e.offsetY);
          ctx.lineWidth = 2;
          ctx.strokeStyle = "#00FF00";
          ctx.stroke();
          ctx.closePath();

          const deltaMove = {
            x: e.offsetX - main.initMousePosition.x,
            y: e.offsetY - main.initMousePosition.y,
          };

          main.showScrewSize =
            (Math.sqrt(Math.pow(deltaMove.x, 2) + Math.pow(deltaMove.y, 2)) *
              10) /
            pxToCenRatio;
          main.realScrewSize = main.showScrewSize;

          $("#measure-length").val(main.realScrewSize.toFixed(2));
          main.updateCamera();

          main.ismeasure_move = false;
        }
      });
    document
      .getElementById(main.elemID)
      .addEventListener("mouseup", function (e) {
        main.previousMousePosition = {
          x: 0,
          y: 0,
        };

        main.isDragging = false;
        main.ismeasure = false;
      });
  };

  //******************************************************
  // Initialize Lights
  //******************************************************
  main.fnInitLights = function () {
    const hemiLight = new THREE.HemisphereLight(0xefefef, 0x333333, 1);

    main.scene.add(hemiLight);
    main.scene.add(new THREE.AmbientLight(0x3f3f3f));
    main.fnAddShadowedLight({ x: 550, y: 200, z: 500 }, 0xdfebff, 1);
    main.fnAddShadowedLight({ x: -550, y: -200, z: -500 }, 0xdfebff, 1);
  };

  //******************************************************
  // Bind with Element
  //******************************************************
  main.fnInitElement = function () {
    document
      .getElementById(main.elemID)
      .appendChild(main.webGLRenderer.domElement);
  };

  //******************************************************
  // Add lights from position, color, intensity parmeters
  //******************************************************
  main.fnAddShadowedLight = function (pos, color, intensity) {
    const light = new THREE.DirectionalLight(color, intensity);
    light.position.set(pos.x, pos.y, pos.z);
    light.position.multiplyScalar(1.3);
    light.castShadow = true;
    light.shadow.mapSize.width = 1024;
    light.shadow.mapSize.height = 1024;

    const d = 300;
    light.shadow.camera.left = -d;
    light.shadow.camera.right = d;
    light.shadow.camera.top = d;
    light.shadow.camera.bottom = -d;
    light.shadow.camera.far = 1000;

    // const helper = new THREE.DirectionalLightHelper(light)
    // main.scene.add(helper)
    main.scene.add(light);
  };

  //******************************************************
  // Real time renderer
  //******************************************************
  main.fnRender = function () {
    // main.orbitControls.update();
    main.webGLRenderer.render(main.scene, main.camera);
    requestAnimationFrame(main.fnRender);
  };

  /* para:
        @   pore type
        @   pore rate           : 0 - 100;
        @   shaft length,       : 20
        @   thread length,      : 15  */
  main.fnCreateModel = function (
    pType,
    porsNum,
    sLenght,
    tLength,
    isMerge = false
  ) {
    main.pType = pType;
    main.porsNum = porsNum;
    main.sLenght = sLenght;
    main.tLenght = tLength;

    if (main.obj_main == null) {
      const cubicGeo = new THREE.BoxGeometry(
        main.head_size.x,
        sLenght + tLength + main.head_size.y,
        main.head_size.z
      );
      const cubicMtl = new THREE.MeshStandardMaterial({
        color: 0x00ff00,
        transparent: true,
        opacity: 0.005,
        side: THREE.DoubleSide,
      });
      main.obj_main = new THREE.Mesh(cubicGeo, cubicMtl);
      main.scene.add(main.obj_main);
    }

    let temp_header = null;
    let temp_empty_shaft = null;
    let temp_shaft = null;
    const temp_top_thread = main.top_thread.clone();
    const temp_middle_thread = main.mid_thread.clone();
    const temp_bottom_thread = main.bot_thread.clone();
    let rotAngle = 0;

    // select models
    if (pType == "small") {
      temp_header = main.head_small.clone();
      temp_empty_shaft = main.shaft_empt_small.clone();
      temp_shaft = main.shaft_min.clone();
      rotAngle = 45;
    } else if (pType == "mediumn") {
      temp_header = main.head_mediumn.clone();
      temp_empty_shaft = main.shaft_empt_mediumn.clone();
      temp_shaft = main.shaft_mediumn.clone();
      rotAngle = 50;
    } else {
      temp_header = main.head_large.clone();
      temp_empty_shaft = main.shaft_empt_large.clone();
      temp_shaft = main.shaft_max.clone();
      rotAngle = 70;
    }

    const box3d = new THREE.Box3();
    const obj_header = new THREE.Object3D();
    const obj_thread = new THREE.Object3D();
    const obj_shaft = new THREE.Object3D();

    // Shaft
    main.obj_main.add(obj_shaft);
    const size_screw = new THREE.Vector3();
    box3d.setFromObject(temp_shaft).getSize(size_screw);
    let size_empty = sLenght;

    const screw_cnt = Math.floor((sLenght / size_screw.y) * (porsNum / 100));
    main.total_shaft = screw_cnt * size_screw.y;

    for (let i = 0; i < screw_cnt; i++) {
      const obj = temp_shaft.clone();

      // if (i % 2 == 1) obj.rotation.y = rotAngle * Math.PI / 180;

      obj.rotation.y = (rotAngle * i * Math.PI) / 180;

      obj.position.y = sLenght / -2 + size_screw.y * i + size_screw.y / 2;
      size_empty -= size_screw.y;
      obj_shaft.add(obj);
    }

    temp_empty_shaft.scale.y = size_empty / size_screw.y;
    temp_empty_shaft.position.y =
      sLenght / -2 + size_screw.y * screw_cnt + size_empty / 2;
    // temp_empty_shaft.position.y = - size_empty;
    obj_shaft.add(temp_empty_shaft);

    // Thread
    main.obj_main.add(obj_thread);
    // calc Pos Of Thread
    const mid_cnt = Math.round(
      (tLength - main.bot_size.y - main.top_size.y) / main.mid_size.y
    );
    const real_size =
      main.mid_size.y * mid_cnt + main.bot_size.y + main.top_size.y;

    for (let i = 0; i < mid_cnt; i++) {
      const mid_obj = temp_middle_thread.clone();
      mid_obj.position.y =
        real_size / -2 + main.bot_size.y + main.mid_size.y * (i + 0.5);
      obj_thread.add(mid_obj);
    }

    obj_thread.add(temp_top_thread);
    obj_thread.add(temp_bottom_thread);
    temp_top_thread.position.y = real_size / 2 - main.top_size.y / 2;
    temp_bottom_thread.position.y = real_size / -2 + main.bot_size.y / 2;

    // Header Setting
    main.obj_main.add(obj_header);
    let obj_Process_header = null;

    if (isMerge) {
      const text = main.createHeadModelNum(pType, sLenght, tLength);
      const txtGeometry = new THREE.TextGeometry(text, {
        size: 1.9,
        height: 0.3,
        curveSegments: 10,
        font: main.font,
        weight: "300",
        style: "normal",
        bevelEnabled: false,
        bevelThickness: 2,
        bevelSize: 1,
      });

      const material = new THREE.MeshPhongMaterial({
        color: 0x5a5a5a,
      });

      const textMesh = new THREE.Mesh(txtGeometry, material);
      textMesh.scale.y = 1.1;

      txtGeometry.computeBoundingBox();
      const planeWidth =
        txtGeometry.boundingBox.max.x - txtGeometry.boundingBox.min.x;

      const alpha = (2 * planeWidth) / ((main.size_width - 0.5) * Math.PI);

      const modifier = new ModifierStack(textMesh);
      const bend = new Bend(alpha, 1, 0);
      modifier.addModifier(bend);
      modifier.apply();

      txtGeometry.computeBoundingBox();
      const textWidth = txtGeometry.boundingBox.max.z - txtGeometry.boundingBox.min.z;

      textMesh.position.x = -planeWidth - 0.17;
      textMesh.position.y = 3;
      textMesh.position.z = textWidth / 2 - 0.3;

      temp_header.traverse(function (child) {
        if (child.isMesh) {
          const headerObj = THREE.CSG.fromMesh(child);
          const textObj = THREE.CSG.fromMesh(textMesh);

          const result = headerObj.subtract(textObj);
          obj_Process_header = THREE.CSG.toMesh(result, child.material);
        }
      });

      obj_header.add(obj_Process_header);
    } else {
      const text = main.createHeadModelNum(pType, sLenght, tLength);
      const txtGeometry = new THREE.TextGeometry(text, {
        size: 1.9,
        height: 0.3,
        curveSegments: 10,
        font: main.font,
        weight: "300",
        style: "normal",
        bevelEnabled: false,
        bevelThickness: 2,
        bevelSize: 1,
      });

      const material = new THREE.MeshPhongMaterial({
        color: 0x5a5a5a,
      });

      const textMesh = new THREE.Mesh(txtGeometry, material);
      textMesh.scale.y = 1.1;

      txtGeometry.computeBoundingBox();
      const planeWidth =
        txtGeometry.boundingBox.max.x - txtGeometry.boundingBox.min.x;

      const alpha = (2 * planeWidth) / ((main.size_width - 0.5) * Math.PI);

      const modifier = new ModifierStack(textMesh);
      const bend = new Bend(alpha, 1, 0);
      modifier.addModifier(bend);
      modifier.apply();

      txtGeometry.computeBoundingBox();
      const textWidth = txtGeometry.boundingBox.max.z - txtGeometry.boundingBox.min.z;

      textMesh.position.x = -planeWidth - 0.17;
      textMesh.position.y = 3;
      textMesh.position.z = textWidth / 2 - 0.3;

      obj_header.add(textMesh);
      obj_header.add(temp_header);
    }

    obj_header.position.y = sLenght / 2;
    obj_shaft.position.y = 0;
    obj_thread.position.y = sLenght / -2 + real_size / -2;

    main.obj_header = obj_header;
    main.obj_thread = obj_thread;
    main.obj_shaft = obj_shaft;

    main.size_shaft = sLenght;
    main.size_thread = tLength;
    main.dragArr.push(main.obj_main);

    $("#screw-length").text(
      Math.round(main.size_shaft + main.size_thread + main.size_head) + "mm"
    );
    $("#slider_num_porse").find("span").html(screw_cnt);
  };

  main.loadAssets = function () {
    let cntModel = 0;

    const box3d = new THREE.Box3();

    const fontLoader = new THREE.TTFLoader();
    const gltfLoader = new THREE.GLTFLoader();
    fontLoader.load("/fonts/ttf/font.ttf", function (json) {
      main.font = new THREE.Font(json);
      checkLoadAssets();
    });

    gltfLoader.setPath("/model/head/");
    gltfLoader.load("head.glb", function (gltf) {
      main.head_small = gltf.scene;
      box3d.setFromObject(main.head_small).getSize(main.head_size);
      main.size_width = main.head_size.x;
      main.size_head = main.head_size.y;
      checkLoadAssets();
    });

    gltfLoader.setPath("/model/head/");
    gltfLoader.load("head_medium.glb", function (gltf) {
      main.head_mediumn = gltf.scene;
      checkLoadAssets();
    });

    gltfLoader.setPath("/model/head/");
    gltfLoader.load("head_large.glb", function (gltf) {
      main.head_large = gltf.scene;
      checkLoadAssets();
    });

    gltfLoader.setPath("/model/thread/");
    gltfLoader.load("bot.glb", function (gltf) {
      main.bot_thread = gltf.scene;
      box3d.setFromObject(main.bot_thread).getSize(main.bot_size);
      checkLoadAssets();
    });

    gltfLoader.setPath("/model/thread/");
    gltfLoader.load("mid.glb", function (gltf) {
      main.mid_thread = gltf.scene;
      box3d.setFromObject(main.mid_thread).getSize(main.mid_size);
      checkLoadAssets();
    });

    gltfLoader.setPath("/model/thread/");
    gltfLoader.load("top.glb", function (gltf) {
      main.top_thread = gltf.scene;
      box3d.setFromObject(main.top_thread).getSize(main.top_size);
      checkLoadAssets();
    });

    gltfLoader.setPath("/model/body/");
    gltfLoader.load("screwEmpty.glb", function (gltf) {
      main.shaft_empt_small = gltf.scene;
      checkLoadAssets();
    });

    gltfLoader.setPath("/model/body/");
    gltfLoader.load("screwEmpty_medium.glb", function (gltf) {
      main.shaft_empt_mediumn = gltf.scene;
      checkLoadAssets();
    });

    gltfLoader.setPath("/model/body/");
    gltfLoader.load("screwEmpty_large.glb", function (gltf) {
      main.shaft_empt_large = gltf.scene;
      checkLoadAssets();
    });

    gltfLoader.setPath("/model/body/");
    gltfLoader.load("pore12mm.glb", function (gltf) {
      main.shaft_min = gltf.scene;
      checkLoadAssets();
    });

    gltfLoader.setPath("/model/body/");
    gltfLoader.load("pore14mm.glb", function (gltf) {
      main.shaft_mediumn = gltf.scene;
      checkLoadAssets();
    });

    gltfLoader.setPath("/model/body/");
    gltfLoader.load("pore16mm.glb", function (gltf) {
      main.shaft_max = gltf.scene;

      console.log(gltf.scene);
      checkLoadAssets();
    });

    function checkLoadAssets() {
      cntModel++;
      if (cntModel === 13) {
        main.fnCreateModel(
          main.porsType,
          main.porsNum,
          main.size_shaft,
          main.size_thread
        );
      }
    }
  };

  // light Remove
  main.modelDirectAdd = function () {
    main.scene.remove(main.obj_main);
    main.dragArr.splice(0, 1);

    main.obj_main.remove(main.obj_header);
    main.obj_main.remove(main.obj_thread);
    main.obj_main.remove(main.obj_shaft);
    main.obj_main.remove(main.obj_text_header);

    main.fnCreateModel(
      main.pType,
      main.porsNum,
      main.sLenght,
      main.tLenght,
      true
    );
    main.scene.remove(main.obj_main);
    main.dragArr.splice(0, 1);

    main.scene.add(main.obj_header);
    main.scene.add(main.obj_thread);
    main.scene.add(main.obj_shaft);
  };

  main.resetObjAdd = function () {
    // main.obj_main = null;

    main.obj_main.remove(main.obj_header);
    main.obj_main.remove(main.obj_thread);
    main.obj_main.remove(main.obj_shaft);

    main.scene.remove(main.obj_main);
    main.scene.remove(main.obj_header);
    main.scene.remove(main.obj_thread);
    main.scene.remove(main.obj_shaft);

    main.scene.add(main.obj_main);
    main.fnCreateModel(main.pType, main.porsNum, main.sLenght, main.tLenght);
  };

  main.fnExportSTL = function (fname, callback) {
    main.modelDirectAdd();

    setTimeout(function () {
      const exporter = new THREE.STLExporter();
      const result = exporter.parse(main.scene);

      main.resetObjAdd();

      callback(result, fname);
    }, 3000);
  };

  main.fnChangeShaft = function (sLength) {
    main.size_shaft = sLength;

    main.obj_main.remove(main.obj_header);
    main.obj_main.remove(main.obj_thread);
    main.obj_main.remove(main.obj_shaft);

    main.fnCreateModel(
      main.porsType,
      main.porsNum,
      main.size_shaft,
      main.size_thread
    );
  };

  main.fnChangeThread = function (tLenght) {
    main.size_thread = tLenght;

    main.obj_main.remove(main.obj_header);
    main.obj_main.remove(main.obj_thread);
    main.obj_main.remove(main.obj_shaft);

    main.fnCreateModel(
      main.porsType,
      main.porsNum,
      main.size_shaft,
      main.size_thread
    );
  };

  main.fnUpdatePorsNum = function (porsNum) {
    main.porsNum = porsNum;

    main.obj_main.remove(main.obj_header);
    main.obj_main.remove(main.obj_thread);
    main.obj_main.remove(main.obj_shaft);

    main.fnCreateModel(
      main.porsType,
      main.porsNum,
      main.size_shaft,
      main.size_thread
    );
  };

  main.fnUpdatePorsSize = function (porsSize) {
    if (porsSize == 0) {
      main.porsType = "small";
      $("#slider_size_porse").find("span").html("S");
      $("#slider_diameter").find("span").html("1.2");
    } else if (porsSize == 1) {
      main.porsType = "mediumn";
      $("#slider_size_porse").find("span").html("M");
      $("#slider_diameter").find("span").html("1.4");
    } else {
      main.porsType = "large";
      $("#slider_size_porse").find("span").html("L");
      $("#slider_diameter").find("span").html("1.6");
    }

    main.obj_main.remove(main.obj_header);
    main.obj_main.remove(main.obj_thread);
    main.obj_main.remove(main.obj_shaft);

    main.fnCreateModel(
      main.porsType,
      main.porsNum,
      main.size_shaft,
      main.size_thread
    );
  };

  main.createHeadModelNum = function (pType, sLenght, tLength) {
    console.log(sLenght, tLength);
    let result = "P_";

    let porSh = Math.floor(sLenght - Math.floor(main.total_shaft));
    result += main.formatString(porSh);

    let sh1 = Math.floor(main.total_shaft);
    result += main.formatString(sh1);

    result += Math.floor(tLength);
    result += "_";

    if (pType == "small") {
      result += "12S5";
    } else if (pType == "mediumn") {
      result += "14M4";
    } else {
      result += "16L3";
    }

    main.exportString = result;
    console.log(result);
    return result;
  };

  main.formatString = function (str) {
    if (str < 10) {
      str = "0" + str;
      return str;
    }

    return str;
  };

  main.exportName = function () {
    return main.exportString;
  };

  main.updateCamera = () => {
    main.zRatio = main.realScrewSize / main.showScrewSize;

    main.camera.zoom = 1 / main.zRatio;
    main.camera.updateProjectionMatrix();
  };

  main.fnInit();
  main.loadAssets();
};

class ViewFunctionsLayoutDocument3DModel {
  fnInitializeLayoutDocument() {
    this.myJs3DViewer = new bone3DViewer();
    //myJs3DViewer.fnCreateModel();
    this.fnInitEvent();
    this.fnInitSlider();
    this.fnInitRadio();
  }

  fnInitEvent() {
    const context = this;
    $("#config_area h3").click(function () {
      if ($(this).parent().hasClass("active")) return;

      $("#config_area .active").animate({
        height: "38px",
      });
      $("#config_area .active h3 span").html("+");
      $("#config_area .active").removeClass("active");

      $(this).parent().addClass("active");

      if ($(this).attr("id") == "shaft" || $(this).attr("id") == "thread") {
        $(this).parent().animate({
          height: "120px",
        });
      } else if ($(this).attr("id") == "pores") {
        $(this).parent().animate({
          height: "220px",
        });
      } else if ($(this).attr("id") == "scale") {
        $(this).parent().animate({
          height: "220px",
        });
      }

      $(this).children("span").html("-");
    });

    $("#select_form_porse").on("change", function () {
      context.myJs3DViewer.porsType = $(this).val();
      context.myJs3DViewer.fnUpdateShaft();
    });

    $("#btn_export").on("click", function () {
      $(this).prop("disabled", true);
      $(this).text("Processing..");
      $("#area_3d").loading({
        message: "Engraving Model..",
      });
      setTimeout(function () {
        context.myJs3DViewer.fnExportSTL(
          "scene.stl",
          context.fnSaveArrayBuffer
        );
      }, 500);
    });
  }

  fnSaveArrayBuffer = (buffer, filename) => {
    this.fnSave(
      new Blob([buffer], {
        type: "application/json",
      }),
      filename
    );
  }

  fnSave = (blob) => {
    uploadModel(blob, this.myJs3DViewer.exportName());
  }

  fnInitRadio() {
    const context = this;

    $('input[type="radio"]').checkboxradio({
      icon: false,
    });

    $("input[name=size]").change(function () {
      if (this.checked) {
        switch (this.id) {
          case "size-1":
            context.myJs3DViewer.fnUpdatePorsSize(0);
            $("#diameter-1").click();
            break;
          case "size-2":
            context.myJs3DViewer.fnUpdatePorsSize(1);
            $("#diameter-2").click();
            break;
          case "size-3":
            context.myJs3DViewer.fnUpdatePorsSize(2);
            $("#diameter-3").click();
            break;
          default:
            break;
        }
      }
    });    
  }

  fnInitSlider() {
    const context = this;
    const min_size_head = context.myJs3DViewer.size_head;
    const min_size_shaft = context.myJs3DViewer.size_shaft;
    const min_size_thread = context.myJs3DViewer.size_thread;

    const canvas = document.getElementById("overflow-path");
    canvas.width = context.myJs3DViewer.screen_width;
    canvas.height = context.myJs3DViewer.screen_height;
    canvas.style.display = "none";

    $("#measure-length").keyup(function () {
      context.myJs3DViewer.realScrewSize = $(this).val();
      context.myJs3DViewer.updateCamera();
      // $('#textbox1').val(this.checked);
    });

    // $( "input[type='text']" ).textinput();

    $("input:text")
      // .button()
      .css({
        font: "inherit",
        color: "inherit",
        "text-align": "left",
        outline: "none",
        cursor: "text",
      });

    $(".slider").slider({
      create: function () {
        switch ($(this).attr("id")) {
          case "slider_head_length":
            $(this).find("span").html(min_size_head);
            break;

          case "slider_shaft_length":
            $(this).find("span").html(min_size_shaft);
            $(this).slider("option", "max", 25);
            break;

          case "slider_thread_length":
            $(this).find("span").html(min_size_thread);
            $(this).slider("option", "max", 20);
            break;

          // case "slider_size_porse":
          //   $(this).find("span").html("S");
          //   $(this).slider("option", "max", 2);
          //   break;
          case "slider_num_porse":
            $(this).slider("option", "max", 100);
            // $(this).find("span").html(myJs3DViewer.porsNum);
            break;
          // case "slider_diameter":
          //   $(this).find("span").html("1.2");
          //   $(this).slider("option", "max", 2);
          //   break;
        }
      },
      slide: function (event, ui) {
        switch ($(this).attr("id")) {
          case "slider_head_length":
            context.myJs3DViewer.fnChangeHeader(ui.value);
            $(this)
              .find("span")
              .html(min_size_head + ui.value);
            context.myJs3DViewer.fnChangeHeader(min_size_head + ui.value);
            break;

          case "slider_shaft_length":
            context.myJs3DViewer.fnChangeShaft(ui.value);
            $(this)
              .find("span")
              .html(min_size_shaft + ui.value);
            context.myJs3DViewer.fnChangeShaft(min_size_shaft + ui.value);
            break;

          case "slider_thread_length":
            context.myJs3DViewer.fnChangeThread(ui.value);
            $(this)
              .find("span")
              .html(min_size_thread + ui.value);
            context.myJs3DViewer.fnChangeThread(min_size_thread + ui.value);
            break;

          case "slider_size_porse":
            //console.log("SIZE VALUE", ui.value);
            context.myJs3DViewer.fnUpdatePorsSize(ui.value);
            $("#slider_diameter").slider("option", "value", ui.value);
            break;
          // let dia1 = "";
          // if (myJs3DViewer.porsType == "small") {
          //   dia1 += "1.2";
          // } else if (myJs3DViewer.porsType == "medium") {
          //   dia1 += "1.4";
          // } else {
          //   dia1 += "1.6";
          // }
          // $("#slider_diameter")
          //   .find("span")
          //   .html(dia1);

          // let result = "";
          // if (myJs3DViewer.porsType == "small") {
          //   result += "S";
          // } else if (myJs3DViewer.porsType == "medium") {
          //   result += "M";
          // } else {
          //   result += "L";
          // }

          // myJs3DViewer.fnUpdatePosition();
          // // myJs3DViewer.fnUpdateShaft();
          // $(this)
          //   .find("span")
          //   .html(result);
          // break;

          case "slider_num_porse":
            context.myJs3DViewer.fnUpdatePorsNum(ui.value);
            // myJs3DViewer.porsNum = ui.value;

            // myJs3DViewer.fnUpdateShaft();
            break;
          case "slider_diameter":
            // myJs3DViewer.fnUpdatePorsSize(ui.value);
            //console.log("DIAMETER VALUE", ui.value);
            context.myJs3DViewer.fnUpdatePorsSize(ui.value);

            // let dia = "";
            // if (myJs3DViewer.porsType == "small") {
            //   dia += "1.2";
            // } else if (myJs3DViewer.porsType == "medium") {
            //   dia += "1.4";
            // } else {
            //   dia += "1.6";
            // }

            $("#slider_size_porse").slider("option", "value", ui.value);
            // let result1 = "";
            // if (myJs3DViewer.porsType == "small") {
            //   result1 += "S";
            // } else if (myJs3DViewer.porsType == "medium") {
            //   result1 += "M";
            // } else {
            //   result1 += "L";
            // }
            // $("#slider_size_porse")
            //   .find("span")
            //   .html(result1);

            // myJs3DViewer.fnUpdatePosition();
            // $(this)
            //   .find("span")
            //   .html(dia);
            break;
        }
      },
    });
  }
};

//******************************************************
// Firebase
//******************************************************
function uploadModel(file, filename) {
  $("#area_3d").loading({
    message: "Uploading Model..",
  });

  const db = firebase.firestore();
  //Get the current user
  const user = firebase.auth().currentUser;
  // Create a root reference
  const storageRef = firebase.storage().ref();
  // Create the file metadata
  const metadata = {
    contentType: "application/vnd.ms-pki.stl",
  };
  // Upload file and metadata to the object
  const uploadTask = storageRef
    .child(`users/${user.uid}/cart/` + filename + ".stl")
    .put(file, metadata);

  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on(
    firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
    function (snapshot) {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log("Upload is " + progress + "% done");
      $("#btn_export").text("Uploading " + Math.round(progress) + "%");
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log("Upload is paused");
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log("Upload is running");
          break;
      }
    },
    function (error) {
      $("#btn_export").text("Done");
      $("#btn_export").prop("disabled", false);
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case "storage/unauthorized":
          // User doesn't have permission to access the object
          alert("Error. No Permission.");
          break;

        case "storage/canceled":
          // User canceled the upload
          alert("Upload Cancelled.");
          break;

        case "storage/unknown":
          // Unknown error occurred, inspect error.serverResponse
          alert("Unknown Error.");
          break;
      }
    },
    function () {
      $("#btn_export").text("Success");
      $("#btn_export").prop("disabled", false);
      // Upload completed successfully, now we can get the download URL
      uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
        db.collection("users")
          .doc(user.uid)
          .collection("cart")
          .add({
            product_id: filename,
            downloadurl: downloadURL,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            standard: false,
          })
          .then(function () {
            console.log("Document successfully written!");
            window.location.replace("/dashboard/option");
          })
          .catch(function (error) {
            console.error("Error writing document: ", error);
            alert("Something went wrong. Please try again.");
          });
      });
    }
  );
}

const newModel = new ViewFunctionsLayoutDocument3DModel();

export { bone3DViewer };
export default newModel;
