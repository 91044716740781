<template lang="pug">
  #not-found-component
    div
      div: img(
        src="https://d3e54v103j8qbb.cloudfront.net/static/page-not-found.211a85e40c.svg"
        alt=""
        class="image-3"
      )
      div.title Page not found
      div The page you are looking for doesn't exit or has been moved.
</template>

<script>
export default {
  name: 'NotFoundComponent',
};
</script>

<style src="./NotFoundComponent.scss" lang="scss" scoped />
